import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    height: 10px;
    width: 100%;
    background-color: ${p.theme.colors.primaryBG};
    border-radius: 5px;
    overflow: hidden;
`
);

export const StyledProgress = styled.div<{ currentWidth: string }>(
    (p) => `
    background-color: ${p.theme.colors.primary};
    height: 100%;
    width: ${p.currentWidth};
`
);
