import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReduxState } from '../../../redux';
import { setPhoneUnverified, signoutUser } from '../../../redux/actions/AuthenticationActions';
import AUTHENTICATION_ACTION_TYPES from '../../../redux/actions/AuthenticationActions/action.types';
import { setIsConversationListLoading, initializeChatListSocket } from '../../../redux/actions/ChatsActions';
import { Header } from '../../atoms';
import InternalRouter from './router';

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;
const StyledDisplay = styled.div`
    flex-grow: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
// Single Page Application Container
const SPAContainer: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const currentUserName = useSelector((state: ReduxState) => {
        const user = state.authenticationReducer.currentUser ? state.authenticationReducer.currentUser.data : null;
        return user ? `${user.namePrefix} ${user.firstName} ${user.lastName}` : '';
    });

    React.useEffect(() => {
        // when loading conversations for the first time, startAt
        dispatch(setIsConversationListLoading(true));
        dispatch(initializeChatListSocket());
    }, []);
    const signout = () => {
        // TODO:
        // Need to abstract all the below dispatches into one function
        dispatch(setPhoneUnverified());
        dispatch({
            type: AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
            payload: {
                isCurrentUserAuthenticated: false
            }
        });
        dispatch(signoutUser());
    };
    return (
        <StyledContainer>
            <StyledDisplay>
                <Header userName={currentUserName} signout={signout} />
                <InternalRouter />
            </StyledDisplay>
        </StyledContainer>
    );
};

export default SPAContainer;
