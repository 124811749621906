import styled from 'styled-components';

export const StyledContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledChatBoxContainer = styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow-y: hidden;
    min-width: 450px;
    position: relative;
`;

export const StyledQuickActionsContainer = styled.div`
    max-width: 370px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const ConfusedDoctorContainer = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledErrorContainer = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const LoaderWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;
