import React from 'react';
import { StyledContainer, Signout, StyledMenuIcon, StyledUserNameContainer, StyledWrapper } from './styles';
import { CollapsedMenuIcon } from '../../../assets/img';
import { IHeader } from './types';
import { useHistory } from 'react-router-dom';
import { LeftNavigation } from '../../containers';
import FloatingNavigation from '../FloatingNavigation';


const Header: React.FunctionComponent<IHeader> = ({ userName, signout }) => {
    const history = useHistory();
    const [isMenuVisible, setIsMenuVisible] = React.useState(false);
    const openMenu = () => setIsMenuVisible(true);
    const handleNav = (currentRoute: string) => {
        setIsMenuVisible(false);
        history.push(currentRoute);
    };
    return (
        <>
            <FloatingNavigation
                isVisible={isMenuVisible}
                setIsVisible={setIsMenuVisible}
            >
                <LeftNavigation
                    getCurrentRoute={handleNav}
                />
            </FloatingNavigation>
            <StyledContainer>
                <StyledWrapper>
                    <StyledMenuIcon
                        src={CollapsedMenuIcon}
                        onClick={openMenu}
                    />
                    <StyledUserNameContainer>
                        Welcome, <b>{userName}</b>
                    </StyledUserNameContainer>
                </StyledWrapper>
                <Signout onClick={signout}>Sign out</Signout>
            </StyledContainer>
        </>
    );
};

export default Header;
