import React from 'react';
import { StyledLabelBackground } from './styles';
import { ILabel } from './types';

const Label: React.FunctionComponent<ILabel> = ({ isActive, children }) => {
    return (
        <StyledLabelBackground isActive={isActive}>
            {children}
        </StyledLabelBackground>
    );
};

export default Label;
