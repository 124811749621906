import { IAuthenticationState } from './types';

export const initialUserData = {
    phoneNumber: '',
    isPhoneNumberVerified: false,
    active: false,
    experience: 0,
    firstName: '',
    lastName: '',
    namePrefix: '',
    qualification: [''],
    userId: '',
    userType: '',
    userProfileUrl: ''
};
export const initialState: IAuthenticationState = {
    currentUser: {
        data: initialUserData,
        loading: true,
        error: null
    },
    isCurrentUserAuthenticated: false
};
