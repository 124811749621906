import React from 'react';
import {
    StyledDiv,
    StyledScrollButton,
    ImgIcon,
    IconContainer,
    StyledContainer,
    StyledScrollButtonWrapper
} from './styles';
import { IInfiniteScroller } from './types';
import { ArrowDown } from '../../../assets/img';

const InfiniteScroller: React.FunctionComponent<IInfiniteScroller> = ({
    children,
    fetchMore,
    styles = '',
    isInverted = false,
    changingChildNumber = 1
}) => {
    const scrollTopRef = React.useRef<HTMLDivElement | null>(null);
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const [observer, setObserver] = React.useState<MutationObserver | null>(null);
    const lastElementId = React.useRef('');
    const [displayScrollButton, setDisplayScrollButton] = React.useState<boolean>(false);

    // Scroll to top/bottom
    const scrollTop = () => {
        scrollTopRef?.current?.scrollIntoView(true);
    };

    // Scroll when a new element is added to the DOM Tree
    // eg: Scroll to the bottom/top whena  new message is received and the DOM is updated
    const onInsert = () => {
        // Scroll only when new element is added and not when already existing elements are fetched in batches
        if (contentRef.current?.children[changingChildNumber]?.id !== lastElementId.current) {
            scrollTop();
            lastElementId.current = contentRef.current?.children[changingChildNumber]?.id || '';
        }
    };

    // MutationObserver to watch for changes being made to the DOM tree
    React.useEffect(() => {
        setObserver(new MutationObserver(onInsert));
    }, []);

    React.useEffect(() => {
        if (!observer || !contentRef) return;
        observer.observe(contentRef.current as unknown as Node, {
            childList: true
        });
        return () => {
            observer.disconnect();
        };
    }, [observer, contentRef]);

    // Handle scroll to top or bottom as per 'isInverted'
    const handleScroll = () => {
        if (contentRef.current) {
            let scrollTop = (contentRef.current as HTMLDivElement).scrollTop;

            // Handling for inverted and non inverted scrolls to display the scroll to bottom button
            const displayButton = isInverted ? scrollTop <= -100 : scrollTop >= 100;
            const hideButton = isInverted ? scrollTop >= -100 : scrollTop <= 100;

            // Display scroll to top/bottom button only when scrolled
            if (displayButton && !displayScrollButton) setDisplayScrollButton(true);
            if (hideButton && displayScrollButton) setDisplayScrollButton(false);

            if (isInverted) {
                scrollTop *= -1;
                scrollTop += 1;
            }
            if (
                (contentRef.current as HTMLDivElement).offsetHeight + scrollTop >=
                (contentRef.current as HTMLDivElement).scrollHeight - 5
            ) {
                fetchMore();
            }
        }
    };
    return (
        <StyledContainer>
            <StyledDiv ref={contentRef} onScroll={handleScroll} $propStyles={styles} $isInverted={isInverted}>
                <div ref={scrollTopRef} />
                {children}
            </StyledDiv>
            {displayScrollButton ? (
                <StyledScrollButtonWrapper>
                    <StyledScrollButton onClick={scrollTop}>
                        <IconContainer>
                            <ImgIcon src={ArrowDown} $isInverted={isInverted} />
                        </IconContainer>
                        Jump to the {isInverted ? 'bottom' : 'top'}
                    </StyledScrollButton>
                </StyledScrollButtonWrapper>
            ) : (
                <></>
            )}
        </StyledContainer>
    );
};

export default InfiniteScroller;
