import React from 'react';
import styled from 'styled-components';
import { ChevronBack } from '../../../assets/img';
import { Button } from '../../atoms';
import { IAppointmentLink } from './types';

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const StyledContent = styled.div`
    width: 100%;
`;
const StyledHeader = styled.div`
    padding: 15px;
    padding-top: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: ${(p) => p.theme.fontSizes.label};
    color: ${(p) => p.theme.colors.muted};
`;
const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    margin-top: 35px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
const AppointmentLinkText = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    margin-bottom: 15px;
    font-weight: 600;
`
);
const StyledLinkContainer = styled.div(
    (p) => `
    padding: 15px;
    background-color: ${p.theme.colors.border};
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow:hidden;
`
);
const StyledText = styled.div(
    (p) => `
    margin-top: 10px;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
`
);
const StyledButtonContainer = styled.div`
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 20px;
`;
const AppointmentLink: React.FunctionComponent<IAppointmentLink> = ({
    setAppointmentLinkView,
    shareLink
}) => {
    const link = 'Please click to book an Appointment';
    const onShareLinkClick = () => {
        shareLink(link);
        setAppointmentLinkView(false);
    };
    return (
        <StyledContainer>
            <StyledContent>
                <StyledHeader onClick={() => setAppointmentLinkView(false)}>
                    <img src={ChevronBack} alt="go_back" />
                    Quick Actions
                </StyledHeader>
                <StyledBody>
                    <AppointmentLinkText>Appointment Link</AppointmentLinkText>
                    <StyledLinkContainer>{link}</StyledLinkContainer>
                    <StyledText>
                        Share the link for the patient to book an appointment on
                        the Loop Health mobile app
                    </StyledText>
                </StyledBody>
            </StyledContent>
            <StyledButtonContainer>
                <Button
                    type="primary"
                    fullWidth={true}
                    onClick={onShareLinkClick}
                >
                    Share Link
                </Button>
            </StyledButtonContainer>
        </StyledContainer>
    );
};

export default AppointmentLink;
