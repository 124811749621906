import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-width: 330px;
    max-width: 350px;
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.colors.mutedBG};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const StyledPadding = styled.div<{ displayError: boolean }>(
    (p) => `
        width: 100%;
        height: ${p.displayError ? '20px' : '50px'};
    `
);

export const StyledChatStatusText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

`
);

export const SearchInputContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledScrollContainer = styled.div`
    overflow-y: auto;
    position: relative;
    width: 100%;
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    bottom: 40px;
    left: 50%;
`;

export const ImgIcon = styled.img`
    height: 100%;
    width: 100%;
`;

export const IconContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`;

export const IconContainer = styled.div`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    height: 25px;
    width: 25px;
`;

export const StyledSearchNullState = styled.div`
    font-size: ${(p) => p.theme.fontSizes.heading};
    padding: 10px;
    color: ${(p) => p.theme.colors.muted};
`;
