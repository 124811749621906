import React from 'react';
import { StyledIcon } from './styles';
import { ProfileDefault } from '../../../assets/img';
import { IProfileIcon } from './types';

const ProfileIcon: React.FunctionComponent<IProfileIcon> = ({ src, size = 48 }) => {
    return <StyledIcon src={src ? src : ProfileDefault} size={size} />;
};

export default ProfileIcon;
