import React from 'react';
import {
    StyledContainer,
    StyledProfileContainer,
    StyledAgeContactContainer,
    StyledPartition,
    StyledPatientCompanyContainer,
    StyledPatientMetaContainer,
    StyledPatientName,
    StyledPatientNameContainer
} from './styles';
import { Label, ProfileIcon } from '..';
import { IPatientCard } from './types';

const PatientCard: React.FunctionComponent<IPatientCard> = ({
    patientAge,
    patientCompany,
    patientMobileNumber,
    patientName,
    patientProfleUrl,
    patientGender,
    patientType,
    patientId = '',
    patientBackupMobileNumber
}) => {
    return (
        <StyledContainer>
            <StyledProfileContainer>
                <ProfileIcon src={patientProfleUrl} />
            </StyledProfileContainer>
            <StyledPatientMetaContainer>
                <StyledPatientNameContainer>
                    <StyledPatientName>{patientName}</StyledPatientName>
                    <Label isActive={true}>{patientType === 'membership' ? 'Membership' : 'Dependent'}</Label>
                </StyledPatientNameContainer>
                <StyledAgeContactContainer>
                    {patientAge && Number(patientAge) > 0 ? (
                        <>
                            {patientAge} Years
                            <StyledPartition>|</StyledPartition>
                        </>
                    ) : (
                        ''
                    )}
                    {patientGender}
                    <StyledPartition>|</StyledPartition>
                    {patientMobileNumber ? '+91' + patientMobileNumber : 'N/A'}
                </StyledAgeContactContainer>
                <StyledPatientCompanyContainer>Company: {patientCompany}</StyledPatientCompanyContainer>
                <StyledPatientCompanyContainer>Member ID: {patientId}</StyledPatientCompanyContainer>
                {patientBackupMobileNumber && patientBackupMobileNumber != patientMobileNumber ? (
                    <StyledPatientCompanyContainer>
                        Alternate Contact: {patientBackupMobileNumber}
                    </StyledPatientCompanyContainer>
                ) : null}
            </StyledPatientMetaContainer>
        </StyledContainer>
    );
};

export default PatientCard;
