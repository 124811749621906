import React from 'react';
import styled from 'styled-components';
import {
    Hi as IMG_HI,
    DoctorsLogin as IMG_DOC,
    ChatIcon,
    PatientIcon,
    PrescriptionIcon
} from '../../../assets/img';
const StyledContainer = styled.div(
    (p) => `
    width: 475px;
    height: 100%;
    background: ${p.theme.colors.loginBG};
    padding: 50px 50px;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        display: none;
    }
`
);
const StyledTitle = styled.h1(
    (p) => `
    color: ${p.theme.colors.white};
    font-size: 34px;
`
);
const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const StyledImage = styled.img`
    margin-left: 15px;
    width: 34px;
`;
const StyledPara = styled.p`
    margin-top: 20px;
    margin-bottom: 70px;
    font-size: 18px;
    color: ${(p) => p.theme.colors.white};
    font-weight: 500;
`;
const StyledDocImage = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 35vh;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;
const WhatsNew = styled.span`
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.white};
    font-weight: 600;
`;
const StyledWhatsNewRow = styled.div`
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const IconContainer = styled.div`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    height: 34px;
    width: 34px;
`;
const WhatsNewText = styled.span(
    (p) => `
    color: ${p.theme.colors.white};
    font-weight: 400;
    font-size: ${p.theme.fontSizes.body};
    margin-left: 10px;
`
);
const ImgIcon = styled.img`
    height: 100%;
    width: 100%;
`;

const LoginPanel: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <StyledRow>
                <StyledTitle>Introducing Helix</StyledTitle>
                <StyledImage src={IMG_HI} />
            </StyledRow>
            <StyledPara>
                A new way to manage your <br />
                consultations and daily tasks with ease
            </StyledPara>
            <div>
                <WhatsNew>What&apos;s New</WhatsNew>
                <StyledWhatsNewRow>
                    <IconContainer>
                        <ImgIcon src={PatientIcon} />
                    </IconContainer>
                    <WhatsNewText>Comprehensive Patient View</WhatsNewText>
                </StyledWhatsNewRow>
                <StyledWhatsNewRow>
                    <IconContainer>
                        <ImgIcon src={ChatIcon} />
                    </IconContainer>
                    <WhatsNewText>Real-time Patient Chat</WhatsNewText>
                </StyledWhatsNewRow>
                <StyledWhatsNewRow>
                    <IconContainer>
                        <ImgIcon src={PrescriptionIcon} />
                    </IconContainer>
                    <WhatsNewText>
                        Effortless Prescription Creation
                    </WhatsNewText>
                </StyledWhatsNewRow>
            </div>
            <StyledDocImage src={IMG_DOC} />
        </StyledContainer>
    );
};

export default LoginPanel;
