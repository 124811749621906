import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const initializeSentry = (): void => {
    Sentry.init({
        dsn:
            'https://bdc315a3a74149949b95525c6fb89dc2@o572839.ingest.sentry.io/6061363',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENVIRONMENT
    });
};
