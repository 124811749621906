import React from 'react';
import styled from 'styled-components';
import { LoopLogo } from '../../../assets/img';
import { LoginPanel, LoginForm } from '../../containers';
import { useDispatch, useSelector } from 'react-redux';
import {
    setPhoneUnverified,
    verifyOTP,
    verifyPhoneNumber
} from '../../../redux/actions/AuthenticationActions';
import { ReduxState } from '../../../redux';

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;
const StyledLoginFormContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
const StyledImage = styled.img`
    margin-bottom: 40px;
    width: 195px;
`;

const LoginPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const user = useSelector(
        (state: ReduxState) => state.authenticationReducer.currentUser
    );
    // set value in store as false, if below is chnaged to false
    const [isPhoneVerified, setisPhoneVerified] = React.useState(false);

    React.useEffect(() => {
        if (!isPhoneVerified) {
            dispatch(setPhoneUnverified());
        }
    }, [isPhoneVerified]);

    React.useEffect(() => {
        if (user && user.data && user.data.isPhoneNumberVerified) {
            setisPhoneVerified(true);
        }
    }, [user]);
    const handleLogin = (otp: string) => {
        if (user && user.data.phoneNumber) {
            dispatch(verifyOTP(otp, user.data.phoneNumber));
        }
    };
    return (
        <StyledContainer>
            <LoginPanel />
            <StyledLoginFormContainer>
                <StyledImage src={LoopLogo} />
                <LoginForm
                    verifyNumber={(phoneNumber) =>
                        dispatch(verifyPhoneNumber(phoneNumber))
                    }
                    errorMessage={user?.error ? user.error.message : ''}
                    setIsNumberVerified={setisPhoneVerified}
                    isNumberVerified={isPhoneVerified}
                    verifyOTP={handleLogin}
                />
            </StyledLoginFormContainer>
        </StyledContainer>
    );
};

export default LoginPage;
