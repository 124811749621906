import React from 'react';
import { createPortal } from 'react-dom';
import { StyledFilterContainer, StyledFilterTitle, StyledFilterElements } from './styles';
import { IFilter } from './types';

const Filter: React.FunctionComponent<IFilter> = ({
    onClick,
    filterOptions,
    title,
    parentRef,
    displayOptions,
    selectedOption
}) => {
    const filterRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (filterRef.current) {
            // create a root node
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'filter-root-loop-health');
            // inserts rootElem as last element of body
            if (document.body.lastElementChild) {
                document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
            }
            // adds the modal reference to the root
            rootElem.appendChild(filterRef.current);
        }
    }, [filterRef.current]);

    React.useEffect(() => {
        if (!filterRef.current) {
            filterRef.current = document.createElement('div');
        }
    }, []);

    return displayOptions && filterRef.current ? (
        createPortal(
            <StyledFilterContainer $top={(parentRef?.offsetTop || 0) + 40} $left={(parentRef?.offsetLeft || 0) - 80}>
                <StyledFilterTitle>{title}</StyledFilterTitle>
                {filterOptions.map((options, index) => (
                    <StyledFilterElements
                        key={index}
                        id={options.value}
                        title={options.name}
                        onClick={onClick}
                        $isSelected={selectedOption === options.value}
                    >
                        {options.name}
                    </StyledFilterElements>
                ))}
            </StyledFilterContainer>,
            filterRef.current
        )
    ) : (
        <></>
    );
};

export default Filter;
