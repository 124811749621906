import React from 'react';
import { createPortal } from 'react-dom';
import { StyledNavOverlay } from './styles';
import { IFloatingNavigation } from './types';

const FloatingNavigation: React.FunctionComponent<IFloatingNavigation> = ({
    children,
    isVisible,
    setIsVisible
}) => {
    const navRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (navRef.current) {
            // create a root node
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'floating-menu-loop-health');
            // inserts rootElem as last element of body
            if (document.body.lastElementChild) {
                document.body.insertBefore(
                    rootElem,
                    document.body.lastElementChild.nextElementSibling
                );
            }
            // adds the modal reference to the root
            rootElem.appendChild(navRef.current);
        }
    }, [navRef.current]);
    React.useEffect(() => {
        if (!navRef.current) {
            navRef.current = document.createElement('div');
        }
    }, []);
    // if the refs have been initialised properly,
    // then port the contents of this component, onto the newly created element 'floating-menu-loop-health'
    return isVisible && navRef.current ? (
        createPortal(
            <StyledNavOverlay onClick={() => setIsVisible(false)}>
                {children}
            </StyledNavOverlay>,
            navRef.current
        )
    ) : (
        <></>
    );
};

export default FloatingNavigation;
