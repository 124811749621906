import styled from 'styled-components';

export const StyledInput = styled.input<{ isError: boolean }>(
    (p) => `
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 1px solid ${
    p.isError ? p.theme.colors.error : p.theme.colors.border
};
    color: ${p.isError ? p.theme.colors.error : p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 15px 20px;
    &::placeholder {
        color: ${p.isError ? p.theme.colors.error : p.theme.colors.muted};
    }
`
);
