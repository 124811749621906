import React from 'react';
import { StyledContainer, StyledProgress } from './styles';
import { IProgressBar } from './types';

const ProgressBar: React.FunctionComponent<IProgressBar> = ({ progress }) => {
    const [currProgress, setCurrProgress] = React.useState(0);
    React.useEffect(() => {
        const newAim = Number(progress);
        const update = setInterval(() => {
            setCurrProgress((prevVal) => {
                if (prevVal > newAim) {
                    clearInterval(update);
                }
                return prevVal + 5;
            });
        }, 1);
    }, [progress]);
    return <StyledContainer>
        <StyledProgress currentWidth={`${currProgress}%`} />
    </StyledContainer>;
};

export default ProgressBar;
