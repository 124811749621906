import styled from 'styled-components';

export const StyledBadge = styled.div(
    (p) => `
    border-radius: 50%;
    height: 22px;
    width: 22px;
    color: ${p.theme.colors.white};
    background-color: ${p.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${p.theme.fontSizes.label};
`
);
