import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 70px;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const Signout = styled.span(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.body};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`
);
export const StyledUserNameContainer = styled.div(
    (p) => `
    color: ${p.theme.colors.secondary};
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 500;
    margin-right: 25px;
    text-transform: capitalize;
`
);
export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledMenuIcon = styled.img`
    margin-right: 10px;
`;
