import { ITheme } from './types';

const theme: ITheme = {
    colors: {
        black: '#3D4144',
        white: '#ffffff',
        border: '#eaecf1',
        muted: 'rgba(61, 65, 68, 0.5)',
        error: '#F5487F',
        primary: '#348F6C',
        secondary: '#58968B',
        primaryBG: 'rgba(88, 150, 139, 0.08)',
        activeBG: 'rgba(149, 159, 190, 0.1)',
        secondaryBG: '#F2F7FA',
        errorBG: 'rgba(245, 72, 127, 0.2)',
        loginBG: 'linear-gradient(152.13deg, #348F6C 0.45%, #6CC59F 103.52%)',
        mutedBG: '#FAFCFD'
    },
    fontSizes: {
        title: '20px',
        heading: '16px',
        body: '14px',
        sectionHeading: '10px',
        label: '12px'
    }
};

export default theme;
