import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBoxHeader, MessagesList, MessageBuilder, FilePreview } from '../';
import { ReduxState } from '../../../redux';
import {
    clearUploadProgress,
    dispatchMessage,
    initializeChatSocket,
    loadConversationMessages,
    setIsConversationMessagesLoading,
    uploadFileToStorage
} from '../../../redux/actions/ChatsActions';
import { IConversationListData, IMessageData } from '../../../redux/reducers/ChatsReducer/types';
import { EndChatContainer, InfiniteScroller, Loader } from '../../atoms';
import { IChatBox } from './types';
import { StyledBlurBox, StyledChatContainer, StyledMessagesContainer, LoaderWrapper } from './styles';

const ChatBox: React.FunctionComponent<IChatBox> = ({
    typedMessage,
    selectedConversationId,
    conversationListItem,
    setTypedMessage
}) => {
    const dispatch = useDispatch();
    // need to use reference, to pass latest updated value to infinite scroll event listener.
    // due to closure, it does not take the latest updated value from the state
    const lastVisibleMsgRef = React.useRef(null);
    const [persistConvId, setPersistConvId] = React.useState<string | null>(null);
    const [persistConvListItem, setPersistConvListItem] = React.useState<IConversationListData | null>(null);
    const conversationIDRef = React.useRef<string | null>(null);
    const uploadProgress = useSelector((state: ReduxState) => state.chatsReducer.uploadProgress);
    const coordinatorId = useSelector((state: ReduxState) => state.authenticationReducer.currentUser?.data.userId);
    const messages = useSelector((state: ReduxState) => state.chatsReducer.conversationMessages);
    const lastVisibleMessage = useSelector((state: ReduxState) => state.chatsReducer.lastVisibleMessage);
    const [displayEndChatContainer, setDisplayEndChatContainer] = React.useState(false);
    const [fileDetails, setFileDetails] = React.useState<File | null>(null);
    const previousMessage = React.useRef<IMessageData | null>(null);

    React.useEffect(() => {
        if (
            messages?.data?.selectedConversationId &&
            messages?.data?.[selectedConversationId][0] &&
            previousMessage.current?.timestamp !== messages?.data?.[selectedConversationId][0].timestamp
        ) {
            previousMessage.current = messages.data ? messages.data[selectedConversationId][0] : null;
        }
    }, [messages]);

    React.useEffect(() => {
        if (selectedConversationId) {
            dispatch(initializeChatSocket(selectedConversationId));
            conversationIDRef.current = selectedConversationId;
            setPersistConvId(selectedConversationId);
        }
    }, [selectedConversationId]);

    React.useEffect(() => {
        if (lastVisibleMessage && lastVisibleMessage[selectedConversationId]) {
            lastVisibleMsgRef.current = lastVisibleMessage[selectedConversationId];
        }
    }, [lastVisibleMessage]);
    React.useEffect(() => {
        if (conversationListItem) {
            setPersistConvListItem(conversationListItem);
        }
    }, [conversationListItem]);
    const fetchNextMessages = () => {
        if (lastVisibleMsgRef.current && conversationIDRef.current && !messages.loading) {
            dispatch(setIsConversationMessagesLoading(true));
            dispatch(loadConversationMessages(conversationIDRef.current, lastVisibleMsgRef.current));
        }
    };
    const setEndChat = (isEndChat: boolean) => {
        if (isEndChat) {
            dispatch(dispatchMessage('', selectedConversationId, true, false));
        }
        setDisplayEndChatContainer(false);
    };
    const uploadFile = (file: File) => {
        if (file) {
            dispatch(uploadFileToStorage(file, selectedConversationId));
        }
    };
    const clearProgress = () => {
        dispatch(clearUploadProgress());
    };
    const sendMessage = (message: string) => {
        const formattedMsg = message.trim();
        if (formattedMsg.length && selectedConversationId) {
            dispatch(dispatchMessage(formattedMsg, selectedConversationId, false, false));
        }
    };

    return (
        (persistConvId && (
            <>
                <StyledBlurBox isBlurBackground={displayEndChatContainer || fileDetails !== null}>
                    {persistConvListItem !== null && (
                        <ChatBoxHeader
                            chatClosedTime={
                                messages.data && messages.data[persistConvId] ?
                                    new Date(messages.data[persistConvId][0].timestamp * 1000) :
                                    new Date()
                            }
                            isChatActive={!!persistConvListItem.isOpen}
                            patientProfileUrl={''}
                            patientName={persistConvListItem.patientName}
                            onEndChatClick={() => setDisplayEndChatContainer(true)}
                        />
                    )}
                    <StyledChatContainer>
                        <StyledMessagesContainer>
                            {messages.loading && (
                                <LoaderWrapper>
                                    <Loader />
                                </LoaderWrapper>
                            )}
                            <InfiniteScroller
                                fetchMore={fetchNextMessages}
                                styles={`
                                width: 100%;
                                overflow-y: auto;
                                display: flex;
                                flex-direction: column-reverse;
                                max-width: 100%;
                            `}
                                isInverted={true}
                            >
                                <MessagesList
                                    messages={
                                        messages && messages.data && messages.data[persistConvId] ?
                                            messages.data[persistConvId] :
                                            []
                                    }
                                    coordinatorId={coordinatorId || ''}
                                />
                            </InfiniteScroller>
                        </StyledMessagesContainer>
                        <MessageBuilder
                            placeholder={'type here...'}
                            value={typedMessage}
                            getValue={setTypedMessage}
                            sendMessage={sendMessage}
                            setFileDetails={setFileDetails}
                        />
                    </StyledChatContainer>
                </StyledBlurBox>
                {displayEndChatContainer && <EndChatContainer setEndChat={setEndChat} />}
                {fileDetails !== null && (
                    <FilePreview
                        file={fileDetails}
                        setFile={setFileDetails}
                        uploadFile={uploadFile}
                        uploadProgress={uploadProgress}
                        clearUploadProgress={clearProgress}
                    />
                )}
            </>
        )) || <></>
    );
};

export default ChatBox;
