import React from 'react';
import { StyledPrimaryButton, StyledEndButton, StyledEndIcon, StyledIconButton, StyledSecondaryButton } from './styles';
import { CloseCircleIcon } from '../../../assets/img';
import { IButton } from './types';

const Button: React.FunctionComponent<IButton> = ({
    type,
    onClick,
    fullWidth = false,
    id = '',
    children,
    innerRef = null
}) => {
    const getButtonType = () => {
        switch (type) {
        case 'primary':
            return (
                <StyledPrimaryButton
                    ref={innerRef}
                    onClick={onClick}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        case 'secondary':
            return (
                <StyledSecondaryButton
                    id={id}
                    ref={innerRef}
                    onClick={onClick}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledSecondaryButton>
            );
        case 'icon':
            return (
                <StyledIconButton
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledIconButton>
            );
        case 'end':
            return (
                <StyledEndButton
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    <StyledEndIcon src={CloseCircleIcon} />
                    {children}
                </StyledEndButton>
            );
        default:
            return (
                <StyledPrimaryButton
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        }
    };
    return getButtonType();
};

export default Button;
