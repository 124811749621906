import React from 'react';
import styled from 'styled-components';
import useFormInput from '../../../utils/hooks/useFormInput';
import { Button, Input, Loader } from '../../atoms';
import { ILoginForm } from './types';
import { FirebaseCaptcha } from '../../../adapters/provider';

const StyledContainer = styled.div(
    (p) => `
    background-color: ${p.theme.colors.secondaryBG};
    width: 450px;
    padding: 25px 15px;
    border-radius: 8px;
    position: relative;
    margin: 0;
    @media (max-width: 768px) {
        max-width: 80%;
    }
`
);

const StyledInputContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
`;
const StyledErrorMessage = styled.div`
    height: 12px;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    color: ${(p) => p.theme.colors.error};
    font-size: ${(p) => p.theme.fontSizes.label};
`;
const StyledInputOTPWrapper = styled.div`
    margin-top: 15px;
`;
const LoginForm: React.FunctionComponent<ILoginForm> = ({
    verifyNumber,
    verifyOTP,
    isNumberVerified,
    setIsNumberVerified,
    errorMessage,
    loading = false
}) => {
    const [lockedNumber, setLockedNumber] = React.useState('');
    const phoneNumber = useFormInput('');
    const otp = useFormInput('');
    const loginButtonRef = React.useRef(null);
    React.useEffect(() => {
        if (loginButtonRef.current) {
            window.recaptchaVerifier = new FirebaseCaptcha.RecaptchaVerifier(
                'login-button-1',
                {
                    size: 'invisible'
                }
            );
        }
    }, [loginButtonRef]);
    React.useEffect(() => {
        if (lockedNumber && isNumberVerified) {
            if (lockedNumber !== phoneNumber.value) {
                setIsNumberVerified(false);
                setLockedNumber('');
                otp.setValue('');
            }
        }
    }, [phoneNumber.value]);
    const handleSubmit = () => {
        if (!isNumberVerified) {
            if (phoneNumber.finalValidationCheck()) {
                setLockedNumber(phoneNumber.value);
                verifyNumber(phoneNumber.value);
                otp.setValue('');
            }
        } else {
            if (
                otp.finalValidationCheck() &&
                lockedNumber === phoneNumber.value
            ) {
                verifyOTP(otp.value);
            } else {
                setLockedNumber('');
                setIsNumberVerified(false);
            }
        }
    };
    return (
        <StyledContainer>
            <h3>Sign in to Helix</h3>
            <StyledInputContainer>
                <Input
                    placeholder={'Mobile Number'}
                    {...phoneNumber}
                    name={'phone'}
                    maxLen={10}
                    type="number"
                />
                {isNumberVerified && (
                    <StyledInputOTPWrapper>
                        <Input
                            placeholder={'Enter OTP'}
                            {...otp}
                            name={'otp'}
                            maxLen={6}
                            type="password"
                        />
                    </StyledInputOTPWrapper>
                )}
            </StyledInputContainer>
            <StyledErrorMessage>
                {errorMessage}
                {phoneNumber.errorText}
            </StyledErrorMessage>
            {isNumberVerified && (
                <Button
                    type="primary"
                    fullWidth={true}
                    id={'login-button-2'}
                    onClick={handleSubmit}
                >
                    {loading ? <Loader inverted/> : 'Verify OTP'}
                </Button>
            )}
            {!isNumberVerified && (
                <Button
                    type="primary"
                    fullWidth={true}
                    id={'login-button-1'}
                    onClick={handleSubmit}
                    innerRef={loginButtonRef}
                >
                    {loading? <Loader inverted/> : 'Login with OTP'}
                </Button>
            )}
        </StyledContainer>
    );
};

export default LoginForm;
