import { IConversationListData, IConversationsList } from '../redux/reducers/ChatsReducer/types';

export const dateTransform = (updatedAtSec: number): any => {
    const value = updatedAtSec * 1000;
    if (value) {
        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        if (seconds < 60) {
            // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        } else if (seconds < 3600) {
            return `${Math.floor(seconds / 60)} min ago`;
        } else if (seconds > 3600 && seconds < 43200) {
            return new Date(value).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
        } else if (seconds >= 259200) {
            return new Date(value)
                .toLocaleString('en-GB', { timeZone: 'UTC' })
                .split(',')[0];
        }
    }
    return `${new Date(value).toLocaleDateString()}`;
};

export const ageFromDateOfBirthday = (dateOfBirth: { seconds: number }): number => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth.seconds * 1000);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const filterConversationList = (
    newList: IConversationsList,
    oldList: IConversationsList
): IConversationsList => {
    const newCurrentList = oldList?.data?.filter((conv) => {
        return newList?.data?.every(
            (cconv) => cconv.conversationId !== conv.conversationId
        );
    });
    const newConversations: IConversationListData[] = [
        ...(newCurrentList || []),
        ...(newList.data || [])
    ];
    return {
        data: newConversations.sort(
            (a, b) => b.updatedAtSeconds - a.updatedAtSeconds
        ),
        loading: false,
        error: null
    } as IConversationsList;
};
