import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { InternalRoutes } from '../../../utils/constants';
import { PatientsPage, ChatPage } from './pages';

const InternalRouter: React.FunctionComponent = () => {
    const location = useLocation();
    const isRouteValid = (pathname: string) => {
        let isValid = false;
        Object.keys(InternalRoutes).forEach((routeKey) => {
            if ((InternalRoutes as any)[routeKey] === pathname) isValid = true;
        });
        return isValid;
    };
    return (
        <Switch>
            {isRouteValid(location.pathname) ? (
                <>
                    <Route exact path={InternalRoutes.chat}>
                        <ChatPage />
                    </Route>
                    <Route exact path={InternalRoutes.patients}>
                        <PatientsPage />
                    </Route>
                </>
            ) : (
                <Redirect to={InternalRoutes.chat} />
            )}
        </Switch>
    );
};

export default InternalRouter;
