import React from 'react';
import styled, { keyframes } from 'styled-components';
import { NavigationItem } from '../../atoms';
import { ChatIcon, PatientIcon, LoopLogo } from '../../../assets/img';
import { InternalRoutes } from '../../../utils/constants';
import { ILeftNavigation } from './types';
import { useLocation } from 'react-router-dom';

const SlideInAnimate = keyframes`
    100% { left: 0; }
`;
const StyledContainer = styled.div`
    position: absolute;
    left: -300px;
    height: 100vh;
    width: 300px;
    min-width: 250px;
    background-color: ${(p) => p.theme.colors.secondaryBG};
    padding-left: 50px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    -webkit-animation: ${SlideInAnimate} 0.5s forwards;
    animation: ${SlideInAnimate} 0.5s forwards;
`;
const StyledImage = styled.img`
    margin-bottom: 70px;
    margin-top: 20px;
    width: 140px;
`;
const LeftNavigation: React.FunctionComponent<ILeftNavigation> = ({ getCurrentRoute }) => {
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = React.useState(location.pathname);

    const handleNav = (e: React.MouseEvent, route: string) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentRoute(route);
        getCurrentRoute(route);
    };
    return (
        <StyledContainer>
            <StyledImage src={LoopLogo} />
            <NavigationItem
                icon={ChatIcon}
                text={'chat'}
                onClick={(e) => handleNav(e, InternalRoutes.chat)}
                isActive={currentRoute === InternalRoutes.chat}
            />
            <NavigationItem
                icon={PatientIcon}
                text={'patients'}
                onClick={(e) => handleNav(e, InternalRoutes.patients)}
                isActive={currentRoute === InternalRoutes.patients}
            />
        </StyledContainer>
    );
};

export default LeftNavigation;
