import React from 'react';
import { StyledText, StyledContainer, StyledButtonRows, StyledHeading } from './styles';
import { HandsDown } from '../../../assets/img';
import { Button } from '../../atoms';
import { IEndChatContainer } from './types';


const EndChatContainer: React.FunctionComponent<IEndChatContainer> = ({ setEndChat }) => {
    return (
        <StyledContainer>
            <img src={HandsDown} alt='Look Below' />
            <StyledHeading>Are you sure?</StyledHeading>
            <StyledText>Ending it will mark the chat as closed.</StyledText>
            <StyledButtonRows>
                <Button type='secondary' onClick={() => setEndChat(true)}>
                    Yes, end chat
                </Button>
                &nbsp;&nbsp;
                <Button type='secondary' onClick={() => setEndChat(false)}>
                    No, don&apos;t end
                </Button>
            </StyledButtonRows>
        </StyledContainer>
    );
};

export default EndChatContainer;
