import styled from 'styled-components';

export const StyledLabelBackground = styled.span<{ isActive: boolean }>(
    (p) => `
    background-color: ${
    p.isActive ? p.theme.colors.primaryBG : p.theme.colors.errorBG
};
    color: ${p.isActive ? p.theme.colors.primary : p.theme.colors.error};
    font-size: ${p.theme.fontSizes.sectionHeading};
    border: 1px solid ${
    p.isActive ? p.theme.colors.primary : p.theme.colors.error
};
    padding: 3px 6px;
    border-radius: 53px;
    font-weight: 500;
`
);
