import styled from 'styled-components';

export const StyledTableRow = styled.div`
    display: table-row;
    width: 100%;
`;
export const StyledTableCell = styled.div(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
    padding: 15px;
    vertical-align:middle;
`
);
