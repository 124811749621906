import styled from 'styled-components';
import { ProfileDefault } from '../../../assets/img';

export const StyledIcon = styled.img<{ size: number }>(
    (p) => `
    height: ${p.size}px;
    width: ${p.size}px;
    min-height: ${p.size}px;
    min-width: ${p.size}px;
    border-radius: 50%;
    border: 1px solid ${p.theme.colors.primary};
    padding: 1px;
    overflow: hidden;
    background-image: url(${ProfileDefault});
    background-repeat:no-repeat;
    background-size:cover;
`
);
