import { IChatsState } from './types';

export const initialState: IChatsState = {
    conversationsList: {
        data: null,
        loading: false,
        error: null
    },
    searchedConversationsList: {
        data: null,
        loading: false,
        error: null
    },
    currentlySelectedConversationId: null,
    conversationMessages: {
        data: null,
        loading: false,
        error: null
    },
    uploadProgress: null,
    currentlySelectedPatient: {
        data: null,
        loading: false,
        error: null
    },
    lastVisibleConversationItem: null,
    lastVisibleMessage: null,
    lastVisibleMessageDetails: null
};
