import React from 'react';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory
} from 'react-router-dom';
import { LoginPage, SPAContainer } from '.';
import { checkIfUserAuthAPI } from '../../adapters';
import { ReduxState } from '../../redux';
import { InternalRoutes, Routes } from '../../utils/constants';
import AUTHENTICATION_ACTION_TYPES from '../../redux/actions/AuthenticationActions/action.types';
import { IUser } from '../../redux/reducers/AuthenticationReducer/types';
import { initialUserData } from '../../redux/reducers/AuthenticationReducer/initialState';
import { refetchUserData } from '../../redux/actions/AuthenticationActions';

const MainRouter: React.FunctionComponent = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector(
        (state: ReduxState) =>
            state.authenticationReducer.currentUser
    );
    const isUserAuthenticated = useSelector(
        (state: ReduxState) =>
            state.authenticationReducer.isCurrentUserAuthenticated
    );
    React.useEffect(() => {
        // first check in store if the user is authenticated
        if (isUserAuthenticated) {
            history.push(InternalRoutes.chat);
        } else {
            // if user is not authenticated in store, check firebase cache
            dispatch({
                type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                payload: {
                    data: userData?.data,
                    error: null,
                    loading: true
                } as IUser
            });
            // check firebase cache for userData persistence
            checkIfUserAuthAPI().then((res: firebase.User | null) => {
                if (res && res.phoneNumber) {
                    // user details exist in firbase cache, refetch data from db and redirect to chat page
                    // set userAuthenticated to true
                    dispatch({
                        type: AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
                        payload: {
                            isCurrentUserAuthenticated: true
                        }
                    });
                    // get all of the user details from the api
                    // userid is the phone number in this case
                    dispatch(refetchUserData(res.phoneNumber.substring(3)));
                    history.push(InternalRoutes.chat);
                } else {
                    // nothing found in firebase cache, set loading to false, and remove any residue of user data
                    // then redirect to login
                    dispatch({
                        type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                        payload: {
                            data: initialUserData,
                            error: null,
                            loading: false
                        } as IUser
                    });
                    dispatch({
                        type: AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
                        payload: {
                            isCurrentUserAuthenticated: false
                        }
                    });
                    history.push(Routes.login);
                }
            });
        }
    }, [isUserAuthenticated]);
    const isRouteValid = (pathname: string) => {
        let isValid = false;
        const routes = { ...Routes, ...InternalRoutes };
        Object.keys(routes).forEach((routeKey) => {
            if ((routes as any)[routeKey] === pathname) isValid = true;
        });
        return isValid;
    };
    return (userData?.loading) ? (
        <></>
    ) : (
        <Switch>
            <Redirect exact from='/' to={Routes.login} />
            <Route exact path={Routes.login}>
                <LoginPage />
            </Route>
            {isUserAuthenticated && isRouteValid(location.pathname) ? (
                <Route path={Routes.app}>
                    <SPAContainer />
                </Route>
            ) : (
                <Redirect to='/' />
            )}
        </Switch>
    );
};

export default MainRouter;
