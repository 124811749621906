import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../assets/img';
import { Button, ProgressBar } from '../../atoms';
import { IFilePreview } from './types';

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
const StyledHeader = styled.div`
    width: 100%;
    height: 72px;
    max-height: 10%;
    background-color: ${(p) => p.theme.colors.primary};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
const StyledPreviewContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
const StyledErrorContainer = styled.div`
    height: 15px;
    width: 100%;
    margin: 8px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const StyledButtonContainer = styled.div`
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const StyledPreviewText = styled.h3(
    (p) => `
    color: ${p.theme.colors.white};
    line-height: 1rem;
    font-weight: 500;
    font-size: ${p.theme.fontSizes.title};
`
);
const StyledFileNameText = styled.h4(
    (p) => `
    margin-left: 8px;
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    font-weight: 300;
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
);
const StyledClosedIcon = styled.img`
    height: 22px;
    max-height: 22px;
    margin-right: 8px;
`;
const StyledErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
`
);
const StyledPDF = styled.iframe`
    max-width: 100%;
    border: none;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
`;
const StyledImage = styled.img`
    max-width: 100%;
    min-height: 10px;
    max-height: 380px;
`;
const FilePreview: React.FunctionComponent<IFilePreview> = ({
    file,
    setFile,
    uploadFile,
    uploadProgress,
    clearUploadProgress
}) => {
    const iFrameRef = React.useRef<HTMLIFrameElement | null>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [errMsg, setErrMsg] = React.useState('');
    React.useEffect(() => {
        if (file) {
            let reference: HTMLIFrameElement | HTMLImageElement | null;
            const isPDF = file.type === 'application/pdf';
            const acceptedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];
            const fileExtension = file.type
                .split('/')[file.type.split('/').length - 1].toLowerCase();
            if (!acceptedFileTypes.includes(fileExtension)) {
                setErrMsg(
                    `Invalid file type. Allowed types are 'pdf', 'jpg', 'jpeg', 'png'!`
                );
                return;
            } else {
                setErrMsg('');
            }
            if (isPDF) {
                reference = iFrameRef.current;
            } else {
                reference = imageRef.current;
            }
            if (iFrameRef && reference) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    if (reference) {
                        reference.src = `${e.target.result}${
                            isPDF ? '#toolbar=0&navpanes=0&view=fitH' : ''
                        }`;
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    }, [file, iFrameRef, imageRef]);

    React.useEffect(() => {
        if (uploadProgress) {
            if (
                uploadProgress.hasUploadStarted &&
                uploadProgress.hasUploadFinished
            ) {
                setTimeout(() => {
                    handleCleanup();
                }, 800);
            }
            if (
                uploadProgress.hasUploadStarted &&
                uploadProgress.hasUploadFailed
            ) {
                setErrMsg('Something went wrong!');
                setTimeout(() => {
                    handleCleanup();
                }, 800);
            }
        }
    }, [uploadProgress]);

    const handleCleanup = () => {
        clearUploadProgress();
        setFile(null);
    };

    const handleSubmit = () => {
        if (file) {
            const acceptedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];
            const fileExtension = file.type
                .split('/')[file.type.split('/').length - 1].toLowerCase();
            if (!acceptedFileTypes.includes(fileExtension)) {
                setErrMsg(
                    `Invalid file type. Allowed types are 'pdf', 'jpg', 'jpeg', 'png'!`
                );
                return;
            }
            if (file.size >= 25728640) {
                setErrMsg('File size is greater than 25MB limit!');
                return;
            }
            uploadFile(file);
            setErrMsg('');
        }
    };
    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleCleanup();
    };

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledClosedIcon src={CloseIcon} onClick={handleClose} />
                <StyledPreviewText>Preview:</StyledPreviewText>
                <StyledFileNameText>{file?.name}</StyledFileNameText>
            </StyledHeader>
            <StyledPreviewContainer>
                {file && file.type === 'application/pdf' ? (
                    <StyledPDF
                        src={'#'}
                        ref={iFrameRef}
                        // below is the standard aspect ratio of A4 size
                        height="200px"
                        width="300px"
                    />
                ) : (
                    <StyledImage ref={imageRef} src={'#'} />
                )}
            </StyledPreviewContainer>
            <StyledErrorContainer>
                {errMsg.length > 0 && (
                    <StyledErrorText>{errMsg}</StyledErrorText>
                )}
            </StyledErrorContainer>
            <StyledButtonContainer>
                {uploadProgress && uploadProgress.hasUploadStarted ? (
                    <ProgressBar progress={uploadProgress.currentProgress} />
                ) : (
                    <Button type="primary" onClick={handleSubmit}>
                        Send
                    </Button>
                )}
            </StyledButtonContainer>
        </StyledContainer>
    );
};

export default FilePreview;
