import React from 'react';
import MessageBox from '../MessageBox';
import { IMessagesList } from './types';

const MessagesList: React.FunctionComponent<IMessagesList> = ({ messages, coordinatorId }) => {
    return (
        <>
            {messages.map(
                (msg, index) =>
                    msg.messageType !== 'rating' && (
                        <MessageBox
                            timestamp={msg.timestamp}
                            isOutgoing={msg.senderId === coordinatorId}
                            key={`${msg.messageId}-${index}`}
                            messageId={msg.messageId}
                            message={msg.message}
                            messageType={msg.messageType}
                            fileName={msg.fileName}
                            fileType={msg.fileType}
                        />
                    )
            )}
        </>
    );
};

export default MessagesList;
