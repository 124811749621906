import { ReduxState } from '../..';
import { initialState } from '../../reducers/AuthenticationReducer/initialState';
import { cancelSockets, getUserDetailsAPI, signoutUserAPI, verifyCaptchaAPI, verifyOTPAPI } from '../../../adapters';
import { initialUserData } from '../../reducers/AuthenticationReducer/initialState';
import { IUser, IUserData } from '../../reducers/AuthenticationReducer/types';
import { IAction } from '../../reducers/types';
import { AppDispatch } from '../../store';
import AUTHENTICATION_ACTION_TYPES from './action.types';
import { resetChatState } from '../ChatsActions';

export const verifyPhoneNumber = (phoneNumber: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => ReduxState
    ): Promise<void> => {
        try {
            const response = await verifyCaptchaAPI(phoneNumber);
            if (response.success) {
                const userData = getState().authenticationReducer.currentUser;
                if (userData) {
                    dispatch({
                        type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                        payload: {
                            data: {
                                ...userData.data,
                                isPhoneNumberVerified: true,
                                phoneNumber
                            },
                            error: null,
                            loading: false
                        } as IUser
                    });
                }
            }
        } catch (e) {
            dispatch({
                type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                payload: {
                    data: initialUserData,
                    error: e,
                    loading: false
                } as IUser
            });
        }
    };
};

export const verifyOTP = (otp: string, phoneNumber: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => ReduxState
    ): Promise<void> => {
        const currentUserData = getState().authenticationReducer.currentUser;
        if (currentUserData) {
            try {
                const response = await verifyOTPAPI(otp, phoneNumber);
                const { data } = response;
                const userData: IUserData = {
                    ...currentUserData.data,
                    active: data.active,
                    experience: data.experience,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    namePrefix: data.namePrefix,
                    qualification: data.qualification,
                    userId: data.userId,
                    userProfileUrl: data.fulImageUrl,
                    userType: data.userType
                };
                dispatch({
                    type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                    payload: {
                        data: userData,
                        error: null,
                        loading: false
                    } as IUser
                });
                dispatch({
                    type: AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
                    payload: {
                        isCurrentUserAuthenticated: true
                    }
                });
            } catch (e) {
                await signoutUserAPI();
                dispatch({
                    type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                    payload: {
                        data: currentUserData.data,
                        error: e,
                        loading: false
                    } as IUser
                });
                dispatch({
                    type:
                        AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
                    payload: {
                        isCurrentUserAuthenticated: false
                    }
                });
            }
        }
    };
};

export const setPhoneUnverified = (): IAction => {
    return {
        type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
        payload: {
            data: initialUserData,
            error: null,
            loading: false
        } as IUser
    };
};

export const signoutUser = () => {
    return async (dispatch: AppDispatch): Promise<void> => {
        try {
            cancelSockets();
            const response = await signoutUserAPI();
            if (response) {
                dispatch({
                    type: AUTHENTICATION_ACTION_TYPES.SIGNOUT_USER,
                    payload: {
                        initialState: initialState
                    }
                });
                dispatch(resetChatState());
            }
        } catch (e) {
            throw e;
        }
    };
};

export const refetchUserData = (userId: string) => {
    return async (dispatch: AppDispatch): Promise<void> => {
        try {
            const userDetails = await getUserDetailsAPI(userId);
            const userData: IUserData = {
                phoneNumber: userId,
                isPhoneNumberVerified: true,
                active: userDetails.active,
                experience: userDetails.experience,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                namePrefix: userDetails.namePrefix,
                qualification: userDetails.qualification,
                userId: userDetails.userId,
                userProfileUrl: userDetails.fulImageUrl,
                userType: userDetails.userType
            };
            dispatch({
                type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                payload: {
                    data: userData,
                    error: null,
                    loading: false
                } as IUser
            });
        } catch (e) {
            dispatch({
                type: AUTHENTICATION_ACTION_TYPES.SET_USER_DATA,
                payload: {
                    data: initialUserData,
                    error: e,
                    loading: false
                } as IUser
            });
            dispatch({
                type: AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED,
                payload: {
                    isCurrentUserAuthenticated: false
                }
            });
        }
    };
};
