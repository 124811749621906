import styled from 'styled-components';

export const StyledFilterContainer = styled.div<{ $top: number; $left: number }>(
    (p) => `
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: ${p.theme.colors.white};
    left: ${p.$left}px;
    top: ${p.$top}px;
    border: 1px solid ${p.theme.colors.border};
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.2);
    z-index: 10000;
`
);

export const StyledFilterTitle = styled.div(
    (p) => `
    background-color: ${p.theme.colors.primary};
    color: ${p.theme.colors.white};
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 0px 12px 0px 12px;
    font-size: ${p.theme.fontSizes.body};
`
);

export const StyledFilterElements = styled.div<{ $isSelected: boolean }>(
    (p) => `
    padding: 12px 10px;
    border-bottom: 1px solid ${p.theme.colors.border};
    &:hover {
        background-color: ${p.theme.colors.primaryBG};
        color: ${p.theme.colors.black};
    }
    font-size: ${p.theme.fontSizes.body};
    &:last-child {
        border-radius: 0px 0px 12px 12px;
    }
    background-color: ${p.$isSelected ? p.theme.colors.primaryBG : ''};
`
);
