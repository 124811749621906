import styled from 'styled-components';

export const StyledImage = styled.img`
    height: 232px;
    width: 232px;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 340px;
`;

export const StyledText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    text-align: center;
`
);
