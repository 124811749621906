import styled from 'styled-components';

export const StyledPrimaryButton = styled.button<{ fullWidth: boolean }>(
    (p) => `
    padding: 0.8rem 1.5rem;
    background-color: ${p.theme.colors.primary};
    color: ${p.theme.colors.white};
    border-radius: 16px;
    font-size: 14px;
    border: 0px;
    ${p.fullWidth ? 'width: 100%;' : ''}
`
);

export const StyledSecondaryButton = styled.button<{ fullWidth: boolean }>(
    (p) => `
    padding: 0.5rem 0.8rem;
    background-color: ${p.theme.colors.white};
    color: ${p.theme.colors.secondary};
    border-radius: 16px;
    font-size: 12px;
    border: 1px solid ${p.theme.colors.secondary};
    ${p.fullWidth ? 'width: 100%;' : ''}
`
);

export const StyledEndButton = styled.button<{ fullWidth: boolean }>(
    (p) => `
    padding: 0.5rem;
    background-color: ${p.theme.colors.white};
    color: ${p.theme.colors.error};
    border-radius: 10px;
    font-size: ${p.theme.fontSizes.body};
    font-weight: 500;
    text-transform: capitalize;
    border: none;
    ${p.fullWidth ? 'width: 100%;' : ''}
    display: flex;
    justify-content: center;
    align-items: center;
`
);
export const StyledEndIcon = styled.img`
    height: 18px;
    width: 18px;
    max-height: 17px;
    max-width: 17px;
    margin-right: 6px;
`;
export const StyledIconButton = styled.button<{ fullWidth: boolean }>(
    (p) => `
    padding: 10px 9px;
    height: 45px;
    width: 42px;
    max-height: 45px;
    max-width: 42px;
    background-color: ${p.theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${p.theme.colors.border};
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.2);
    ${p.fullWidth ? 'width: 100%;' : ''}
`
);
