import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const StyledHeading = styled.h2(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.black};
    font-weight: 700;
`
);
export const StyledText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
`
);
export const StyledButtonRows = styled.div`
    margin-top: 25px;
`;
