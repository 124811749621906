import { filterConversationList } from '../../../utils/utility';
import CHAT_ACTION_TYPES from '../../actions/ChatsActions/action.types';
import { IAction } from '../types';
import { initialState } from './initialState';
import { IChatsState } from './types';

const chatsReducer = (
    _state: IChatsState = initialState,
    { type, payload }: IAction
): IChatsState => {
    switch (type) {
    case CHAT_ACTION_TYPES.SET_INITIAL_STATE:
        return { ...initialState };
    case CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES:
        return {
            ..._state,
            conversationMessages: payload.conversationMessages,
            lastVisibleMessage: payload.lastVisibleMessage
        };
    case CHAT_ACTION_TYPES.LOAD_SEARCHED_CONVERSATIONS_LIST:
        return {
            ..._state,
            searchedConversationsList: payload.searchedConversationsList
        };
    case CHAT_ACTION_TYPES.LOAD_SOCKET_CONV_LIST:
        return {
            ..._state,
            conversationsList: filterConversationList(
                payload.conversationsList,
                _state.conversationsList
            ),
            searchedConversationsList: filterConversationList(
                payload.conversationsList,
                _state.searchedConversationsList
            )
        };
    case CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST:
        return {
            ..._state,
            conversationsList: payload.conversationsList,
            lastVisibleConversationItem: payload.lastVisibleConversationItem
        };
    case CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_CONVERSATION_ID:
        return {
            ..._state,
            currentlySelectedConversationId:
                payload.currentlySelectedConversationId
        };
    case CHAT_ACTION_TYPES.SET_UPLOAD_PROGRESS:
        return {
            ..._state,
            uploadProgress: payload.uploadProgress
        };
    case CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_PATIENT_DATA:
        return {
            ..._state,
            currentlySelectedPatient: payload.currentlySelectedPatient
        };
    default:
        return _state;
    }
};
export default chatsReducer;
