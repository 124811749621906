import React from 'react';
import { StyledTableRow, StyledTableCell } from './styles';
const TableHeader: React.FunctionComponent = () => {
    return (
        <StyledTableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Number</StyledTableCell>
            <StyledTableCell>Upcoming Consultation</StyledTableCell>
            <StyledTableCell>Membership Status</StyledTableCell>
            <StyledTableCell>Membership Type</StyledTableCell>
            <StyledTableCell>Company Name</StyledTableCell>
        </StyledTableRow>
    );
};

export default TableHeader;
