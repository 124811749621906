export const Routes = {
    login: '/login',
    app: '/app'
};

export const InternalRoutes = {
    home: Routes.app,
    chat: `${Routes.app}/chat`,
    patients: `${Routes.app}/patients`
};

export type IFilterOptions = {
    name: string;
    value: 'mobile' | 'loopId' | 'name';
};

export const FilterOptions: IFilterOptions[] = [
    {
        name: 'Mobile',
        value: 'mobile'
    },
    {
        name: 'LoopId',
        value: 'loopId'
    },
    {
        name: 'User Name',
        value: 'name'
    }
];
