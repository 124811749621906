import React from 'react';
import styled from 'styled-components';
import { IMessageBuilder } from './types';
import { Button } from '../../atoms';
import { AttachmentIcon, SendIcon } from '../../../assets/img';

const StyledAttachmentIcon = styled.img`
    max-height: 100%;
    position: absolute;
    margin-right: 15px;
    width: 12px;
    right: 0;
    bottom: 3px;
    padding: 0px 6px;
    background-color: transparent;
    transform: translateY(-50%);
`;
const StyledSendIcon = styled.img`
    max-height: 19px;
`;
const StyledTextarea = styled.textarea(
    (p) => `
    resize: none;
    width: 100%;
    min-height: 20px;
    max-height: 350px;
    padding: 15px;
    padding-right: 39px;
    margin: 0;
    background-color: ${p.theme.colors.white};
    border: 1px solid ${p.theme.colors.border};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.07);
    white-space: normal;
    text-align: justify;
    -moz-text-align-last: start; /* Firefox 12+ */
    text-align-last: start;
    &::-webkit-input-placeholder {
        color: ${p.theme.colors.muted};
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: ${p.theme.colors.muted};  
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        color: ${p.theme.colors.muted};  
    }

    &:-ms-input-placeholder {
        color: ${p.theme.colors.muted};  
    }

    &::placeholder {
        color: ${p.theme.colors.muted};  
    }
`
);
const StyledContainer = styled.div`
    padding: 20px 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    background-color: ${(p) => p.theme.colors.white};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
const StyledWrapper = styled.span`
    width: 100%;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
const MessageBuilder: React.FunctionComponent<IMessageBuilder> = ({
    placeholder,
    value,
    getValue,
    sendMessage,
    setFileDetails
}) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const [rows, setRows] = React.useState(0);
    React.useEffect(() => {
        if ((value.match(/[^\n]*\n[^\n]*/gi) || []).length + 1 < 21) {
            setRows((value.match(/[^\n]*\n[^\n]*/gi) || []).length);
        } else {
            setRows(20);
        }
    }, [value]);
    React.useEffect(() => {
        if (textAreaRef.current) {
            document.addEventListener('DOMNodeInserted', () => {
                const grammarly = document.getElementsByTagName('grammarly-extension');
                if (grammarly.length == 2) {
                    (grammarly[0] as HTMLElement).style.top = '11px';
                    (grammarly[0] as HTMLElement).style.left = '0px';
                    (grammarly[1] as HTMLElement).style.top = '0px';
                    (grammarly[1] as HTMLElement).style.left = '0px';
                }
            });
        }
    }, [textAreaRef]);
    const onAddChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== null) {
            getValue(e.target.value);
        }
    };
    const onFileSelected = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.currentTarget.files;
        if (files) {
            const currentFile = files[0];
            if (currentFile) {
                setFileDetails(currentFile);
            }
        }
        e.currentTarget.value = '';
    };
    const handleSubmit = (e: React.KeyboardEvent) => {
        if (e.which === 13 && e.shiftKey !== true) {
            e.preventDefault();
            e.stopPropagation();
            if (value) {
                sendMessage(value.trim());
                getValue('');
                setRows(0);
            }
        }
    };
    const handleSendButtonClick = () => {
        if (value) {
            sendMessage(value.trim());
            getValue('');
            setRows(0);
        }
    };

    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledTextarea
                    ref={textAreaRef}
                    id='styled-textarea'
                    data-grammarly
                    value={value}
                    placeholder={placeholder}
                    onKeyPress={(e) => handleSubmit(e)}
                    onChange={onAddChange}
                    rows={rows + 1}
                ></StyledTextarea>
                <input
                    type='file'
                    id='attachmentInput'
                    style={{ display: 'none' }}
                    accept='.jpg,.jpeg,.png,.pdf'
                    onChange={onFileSelected}
                />
                <StyledAttachmentIcon
                    src={AttachmentIcon}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        document.getElementById('attachmentInput')?.click();
                    }}
                />
            </StyledWrapper>
            <Button onClick={handleSendButtonClick} type='icon'>
                <StyledSendIcon src={SendIcon} alt='send button' />
            </Button>
        </StyledContainer>
    );
};

export default MessageBuilder;
