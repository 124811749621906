import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    border-radius: 16px;
    min-width: 305px;
    min-height: 88px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid ${p.theme.colors.border};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
);

export const StyledProfileContainer = styled.div`
    margin-left: 3px;
`;
export const StyledPatientMetaContainer = styled.div`
    flex-grow: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
export const StyledPatientNameContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const StyledAgeContactContainer = styled.div`
    color: ${(p) => p.theme.colors.muted};
    margin: 5px 0px;
    font-size: ${(p) => p.theme.fontSizes.label};
    text-transform: capitalize;
`;
export const StyledPatientCompanyContainer = styled.div`
    color: ${(p) => p.theme.colors.muted};
    font-size: ${(p) => p.theme.fontSizes.label};
    margin-bottom: 5px;
`;
export const StyledPatientName = styled.span(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    text-transform: capitalize;
`
);
export const StyledPartition = styled.span`
    color: ${(p) => p.theme.colors.border};
    margin-right: 4px;
    margin-left: 4px;
`;
