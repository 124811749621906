import React from 'react';
import {
    StyledContainer,
    IconContainer,
    StyledText,
    StyledSVG
} from './styles';
import { INavigsationItem } from './types';

const NavigationItem: React.FunctionComponent<INavigsationItem> = ({
    text,
    icon,
    onClick,
    isActive
}) => {
    return (
        <StyledContainer onClick={onClick} isActive={isActive}>
            <IconContainer>
                <StyledSVG src={icon} $isActive={isActive} />
            </IconContainer>
            <StyledText>{text}</StyledText>
        </StyledContainer>
    );
};

export default NavigationItem;
