import React from 'react';
import styled from 'styled-components';
import { TableHeader, TableRow } from '../../../../atoms';

const StyledContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

const StyledTable = styled.div`
    display: table;
    width: 100%;
    min-width: 500px;
`;
const PatientsPage: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <h3>Patients</h3>
            <StyledTable>
                <TableHeader />
                <TableRow
                    patientName={'Himanshu Ganapavarapu'}
                    active={true}
                    patientProfileUrl=''
                    patientNumber='7020008480'
                    nextConsultation='17th May'
                    membershipType='Individual'
                    patientCompany='Some Company Name'
                    onClick={() => null}
                />
                <TableRow
                    patientName={'Himanshu Ganapavarapu'}
                    active={false}
                    patientProfileUrl=''
                    patientNumber='7020008480'
                    nextConsultation='17th May'
                    membershipType='Individual'
                    patientCompany='Some Company Name'
                    onClick={() => null}
                />
                <TableRow
                    patientName={'Himanshu Ganapavarapu'}
                    active={true}
                    patientProfileUrl=''
                    patientNumber='7020008480'
                    nextConsultation='17th May'
                    membershipType='Individual'
                    patientCompany='Some Company Name'
                    onClick={() => null}
                />
                <TableRow
                    patientName={'Himanshu Ganapavarapu'}
                    active={true}
                    patientProfileUrl=''
                    patientNumber='7020008480'
                    nextConsultation='17th May'
                    membershipType='Individual'
                    patientCompany='Some Company Name'
                    onClick={() => null}
                />
            </StyledTable>
        </StyledContainer>
    );
};

export default PatientsPage;
