import React from 'react';
import { ConversationItem } from '..';
import useFormInput from '../../../utils/hooks/useFormInput';
import { InfiniteScroller, Input, Loader, Filter } from '../../atoms';
import { IConversationList } from './types';
import ErrorContainer from '../../atoms/ErrorContainer';
import {
    StyledContainer,
    StyledPadding,
    StyledChatStatusText,
    SearchInputContainer,
    StyledScrollContainer,
    LoaderWrapper,
    ImgIcon,
    IconContainer,
    IconContainerWrapper,
    StyledSearchNullState
} from './styles';
import { Filter as FilterImg } from '../../../assets/img';
import { FilterOptions } from '../../../utils/constants';

const ConversationList: React.FunctionComponent<IConversationList> = ({
    conversationsList,
    setSelectedConversationId,
    selectedConversationId,
    setSearchText,
    getNextList,
    showLoader,
    setFilterMode,
    filterMode
}) => {
    const searchInput = useFormInput('');
    const filterRef = React.useRef<HTMLDivElement | null>(null);
    const [openConvCount, setOpenConvCount] = React.useState(0);
    const [closedConvCount, setClosedConvCount] = React.useState(0);
    React.useEffect(() => {
        setSearchText(searchInput.debounceValue);
    }, [searchInput.debounceValue]);
    React.useEffect(() => {
        setOpenConvCount(conversationsList.filter((conv) => conv.isOpen).length);
        setClosedConvCount(conversationsList.filter((conv) => !conv.isOpen).length);
    }, [conversationsList]);
    const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);
    const [displayFilter, setDisplayFilter] = React.useState(false);
    const iconRef = React.useRef<HTMLDivElement | null>(null);

    // Listener to close the filter drawer when clicked outside of the drawer
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [displayFilter]);

    const handleClickOutside = (event: MouseEvent) => {
        if (filterRef.current && displayFilter && !filterRef.current.contains(event.target as Node)) {
            setDisplayFilter(!displayFilter);
        }
    };

    React.useEffect(() => {
        setInterval(() => {
            setIsOnline(window.navigator.onLine);
        }, 100);
    }, []);

    const onFilterClick = (e: React.MouseEvent) => {
        setSearchText('');
        searchInput.setValue('');
        setDisplayFilter(!displayFilter);
        setFilterMode((e.target as HTMLDivElement).id);
    };

    return (
        <StyledContainer>
            <StyledPadding displayError={!isOnline} />
            {!isOnline ? (
                <ErrorContainer
                    text="Internet Disconnected!
            Please check your internet connection and try again."
                />
            ) : null}
            <SearchInputContainer>
                <Input {...searchInput} name="search" placeholder={`Search by ${filterMode}`} />
                <IconContainerWrapper onClick={() => setDisplayFilter(!displayFilter)} ref={iconRef}>
                    <IconContainer>
                        <ImgIcon src={FilterImg} />
                    </IconContainer>
                </IconContainerWrapper>
            </SearchInputContainer>
            <div ref={filterRef}>
                <Filter
                    displayOptions={displayFilter}
                    parentRef={iconRef.current}
                    title={'Search By'}
                    filterOptions={FilterOptions}
                    onClick={onFilterClick}
                    selectedOption={filterMode}
                />
            </div>
            {conversationsList.length ? (
                <StyledScrollContainer>
                    <InfiniteScroller fetchMore={getNextList} changingChildNumber={2}>
                        {openConvCount > 0 && <StyledChatStatusText>Open Chats {openConvCount}</StyledChatStatusText>}
                        {conversationsList.map(
                            (conv, i) =>
                                conv.isOpen && (
                                    <ConversationItem
                                        key={i}
                                        lastMessage={conv.lastMessage}
                                        lastMessageTime={conv.lastMessageTime}
                                        patientName={conv.patientName}
                                        isActive={conv.conversationId === selectedConversationId}
                                        onClick={() => setSelectedConversationId(conv.conversationId)}
                                        // TODO: Need to think about patient profile url
                                        patientProfile={''}
                                        unreadCount={conv.doctorUnreadCount}
                                        isOpen={conv.isOpen}
                                        conversationId={conv.conversationId}
                                    />
                                )
                        )}
                        {closedConvCount > 0 && (
                            <StyledChatStatusText>Closed Chats {closedConvCount}</StyledChatStatusText>
                        )}
                        {conversationsList.map(
                            (conv, i) =>
                                !conv.isOpen && (
                                    <ConversationItem
                                        key={i}
                                        lastMessage={conv.lastMessage}
                                        lastMessageTime={conv.lastMessageTime}
                                        patientName={conv.patientName}
                                        onClick={() => setSelectedConversationId(conv.conversationId)}
                                        isActive={conv.conversationId === selectedConversationId}
                                        // TODO: Need to think about patient profile url
                                        patientProfile={''}
                                        unreadCount={conv.doctorUnreadCount}
                                        isOpen={conv.isOpen}
                                        conversationId={conv.conversationId}
                                    />
                                )
                        )}
                    </InfiniteScroller>
                    {showLoader && (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    )}
                </StyledScrollContainer>
            ) : (
                <StyledSearchNullState>
                    No user found with {filterMode} - {searchInput.value}
                </StyledSearchNullState>
            )}
        </StyledContainer>
    );
};

export default ConversationList;
