import React from 'react';
import styled from 'styled-components';
import { Button, ProfileIcon } from '../../atoms';
import { IChatBoxHeader } from './types';

const StyledContainer = styled.div(
    (p) => `
    width: 100%;
    height: 80px;
    min-height: 80px;
    padding: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${p.theme.colors.white};
    border-bottom: 1px solid ${p.theme.colors.border};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
);
const UserLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const UserLabelText = styled.div(
    (p) => `
    margin-left: 13px;
    font-weight: 600;
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.black};
    text-transform: capitalize;
`
);
const StyledClosedText = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
);
const ChatBoxHeader: React.FunctionComponent<IChatBoxHeader> = ({
    patientName,
    patientProfileUrl,
    onEndChatClick,
    isChatActive,
    chatClosedTime
}) => {
    return (
        <StyledContainer>
            <UserLabelContainer>
                <ProfileIcon src={patientProfileUrl} size={42} />
                <UserLabelText>{patientName}</UserLabelText>
            </UserLabelContainer>
            {isChatActive ? (
                <Button onClick={onEndChatClick} type="end">
                    End Chat
                </Button>
            ) : (
                <StyledClosedText>
                    <span>Chat Closed</span>
                    <span>{chatClosedTime.toDateString()}</span>
                    <span>
                        {chatClosedTime.toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        })}
                    </span>
                </StyledClosedText>
            )}
        </StyledContainer>
    );
};

export default ChatBoxHeader;
