import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import {
    ConversationList,
    QuickActions,
    ChatBox
} from '../../../../containers';
import { ConfusedDoctor, Loader } from '../../../../atoms';
import {
    getPatientData,
    loadConversations,
    searchForConversation,
    setCurrentlySelectedConversationId,
    setIsConversationListLoading,
    setIsSearchConversationListLoading
} from '../../../../../redux/actions/ChatsActions';
import { IConversationListData } from '../../../../../redux/reducers/ChatsReducer/types';
import ErrorContainer from '../../../../atoms/ErrorContainer';
import {
    StyledContainer,
    StyledChatBoxContainer,
    StyledQuickActionsContainer,
    ConfusedDoctorContainer,
    StyledErrorContainer,
    LoaderWrapper
} from './styles';


const ChatPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const lastVisibleRef = React.useRef(null);
    const searchedConversationsList = useSelector(
        (state: ReduxState) => state.chatsReducer.searchedConversationsList
    );
    const conversationsList = useSelector(
        (state: ReduxState) => state.chatsReducer.conversationsList
    );
    const lvC = useSelector(
        (state: ReduxState) => state.chatsReducer.lastVisibleConversationItem
    );
    const selectedConversationId = useSelector(
        (state: ReduxState) =>
            state.chatsReducer.currentlySelectedConversationId
    );
    const [localConversationsList, setLocalConversationsList] = React.useState<
        IConversationListData[]
    >([]);
    const [searchText, setSearchText] = React.useState('');
    const [typedMessage, setTypedMessage] = React.useState('');
    const currentPatientData = useSelector((state: ReduxState) => state.chatsReducer.currentlySelectedPatient);
    const [filterMode, setFilterMode] = React.useState('name');

    React.useEffect(() => {
        if (lvC) {
            lastVisibleRef.current = lvC;
        }
    }, [lvC]);
    React.useEffect(() => {
        if (selectedConversationId) {
            dispatch(getPatientData(selectedConversationId));
        }
    }, [selectedConversationId]);
    React.useEffect(() => {
        if (
            searchText.length &&
            searchedConversationsList &&
            searchedConversationsList.data?.length
        ) {
            setLocalConversationsList(searchedConversationsList.data);
        } else if (searchText.length && !searchedConversationsList.data?.length) {
            setLocalConversationsList([]);
        } else {
            setLocalConversationsList(conversationsList.data ? conversationsList.data : []);
        }
    }, [searchedConversationsList, conversationsList.data, searchText]);
    React.useEffect(() => {
        if (searchText.length) {
            dispatch(setIsSearchConversationListLoading(true));
            dispatch(searchForConversation(searchText, filterMode));
        }
    }, [searchText]);
    const loadNextListOfConversations = () => {
        if (!conversationsList.loading) {
            dispatch(setIsConversationListLoading(true));
            dispatch(loadConversations(lastVisibleRef.current));
        }
    };
    const getCurrentlySelectedChat = () => {
        return [
            ...(searchedConversationsList.data || []),
            ...(conversationsList.data || [])
        ].filter(
            (conversation) =>
                conversation.conversationId === selectedConversationId
        )[0];
    };
    return (
        <StyledContainer>
            <ConversationList
                conversationsList={localConversationsList}
                selectedConversationId={selectedConversationId}
                setSearchText={setSearchText}
                setSelectedConversationId={(id) => {
                    setTypedMessage('');
                    dispatch(setCurrentlySelectedConversationId(id));
                }}
                showLoader={conversationsList.loading || searchedConversationsList.loading}
                getNextList={loadNextListOfConversations}
                setFilterMode={setFilterMode}
                filterMode={filterMode}
            />
            {currentPatientData.loading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : selectedConversationId !== null && currentPatientData.data ? (
                <>
                    <StyledChatBoxContainer>
                        <ChatBox
                            setTypedMessage={setTypedMessage}
                            typedMessage={typedMessage}
                            selectedConversationId={selectedConversationId}
                            conversationListItem={getCurrentlySelectedChat()}
                        />
                    </StyledChatBoxContainer>
                    <StyledQuickActionsContainer>
                        <QuickActions />
                    </StyledQuickActionsContainer>
                </>
            ) : selectedConversationId !== null && !currentPatientData.data ? (
                <StyledErrorContainer>
                    <ErrorContainer text="This user is not found in the system" />
                </StyledErrorContainer>
            ) : (
                <ConfusedDoctorContainer>
                    <ConfusedDoctor
                        text={
                            conversationsList.data?.length ?
                                'Please select a chat conversation to interact with patient' :
                                'Seems like you don’t have any chats yet, worry not. Check back again in a while.'
                        }
                    />
                </ConfusedDoctorContainer>
            )}
        </StyledContainer>
    );
};

export default ChatPage;
