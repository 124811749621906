import styled from 'styled-components';

export const StyledDiv = styled.div<{ $propStyles: string; $isInverted: boolean }>(
    (p) => `
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-bottom: 75px;
    ${p.$isInverted ? 'padding-top: 100px;' : ''}
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    ${p.$propStyles}
`
);

export const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const StyledScrollButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    margin-bottom: 10px;
`;

export const StyledScrollButton = styled.div(
    (p) => `
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${p.theme.colors.primary};
    padding: 10px;
    border-radius: 20px;
    opacity: 0.9;
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.label};
    cursor: pointer;

`
);

export const ImgIcon = styled.img<{ $isInverted: boolean }>(
    (p) => `
    height: 100%;
    width: 100%;
    ${p.$isInverted ? '' : 'transform: scaleY(-1);'}
`
);

export const IconContainer = styled.div`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;
