import React from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import MainRouter from './components/pages/router';

// declaring some global interfaces for firebase recaptcha
declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult;
        analytics: any;
    }
}

const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
`;
const App: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <MainRouter />
        </StyledContainer>
    );
};

export default App;
