import React from 'react';
import { StyledContainer, StyledImage } from './styles';
import { WarningIcon } from '../../../assets/img';
import { IErrorContainer } from './types';

const ErrorContainer: React.FunctionComponent<IErrorContainer> = ({ text }) => {
    return (
        <StyledContainer>
            <StyledImage src={WarningIcon} />
            {text}
        </StyledContainer>
    );
};

export default ErrorContainer;
