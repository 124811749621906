import AUTHENTICATION_ACTION_TYPES from '../../actions/AuthenticationActions/action.types';
import { IAction } from '../types';
import { initialState } from './initialState';
import { IAuthenticationState } from './types';

const authenticationReducer = (
    _state: IAuthenticationState = initialState,
    { type, payload }: IAction
): IAuthenticationState => {
    switch (type) {
    case AUTHENTICATION_ACTION_TYPES.SET_USER_DATA:
        return {
            ..._state,
            currentUser: payload
        };
    case AUTHENTICATION_ACTION_TYPES.SET_IS_USER_AUTHENTICATED:
        return {
            ..._state,
            isCurrentUserAuthenticated: payload.isCurrentUserAuthenticated
        };
    case AUTHENTICATION_ACTION_TYPES.SIGNOUT_USER:
        return {
            ...payload
        };
    default:
        return _state;
    }
};
export default authenticationReducer;
