import styled from 'styled-components';

export const StyledBlurBox = styled.div<{ isBlurBackground: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid ${(p) => p.theme.colors.border};
    max-width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    align-items: center;
    ${(p) =>
        p.isBlurBackground &&
        `
        filter: blur(20px);
        -webkit-filter: blur(20px);
    `}
`;

export const StyledChatContainer = styled.div`
    flex-grow: 1;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledMessagesContainer = styled.div`
    width: 100%;
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    max-width: 100%;
`;
export const LoaderWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
