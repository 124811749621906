import { AppDispatch } from '../../store';
import {
    getBatchCountUpdate,
    getConversationsListAPI,
    getPatientDataAPI,
    initializeChatSocketAPI,
    loadConversationMessagesAPI,
    searchForConversationAPI,
    sendMessageAPI,
    sendMessageAttachmentAPI,
    sendNotificationAPI,
    uploadFileToStorageAPI,
    initializeChatListSocketAPI,
    searchForConversationViaMobileAPI,
    searchForConversationViaUserId
} from '../../../adapters';
import { ReduxState } from '../..';
import {
    IConversationListData,
    IConversationMessages,
    IConversationsList,
    IMessageData,
    IMessageObject,
    IPatient,
    IUploadProgress
} from '../../reducers/ChatsReducer/types';
import * as uuid from 'uuid';
import CHAT_ACTION_TYPES from './action.types';
import { FirebaseTimestamp } from '../../../adapters/provider';
import { IAction } from '../../reducers/types';

export const setIsConversationListLoading = (isLoading: boolean) => {
    return (dispatch: AppDispatch, getState: () => ReduxState): void => {
        const currentConversationList = getState().chatsReducer.conversationsList;
        dispatch({
            type: CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST,
            payload: {
                conversationsList: {
                    data: currentConversationList.data,
                    loading: isLoading,
                    error: currentConversationList.error
                } as IConversationsList
            }
        });
    };
};
export const loadConversations = (startAt: any | null) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const currentUserId = getState().authenticationReducer.currentUser?.data.userId;
        const list = getState().chatsReducer.conversationsList.data;
        const currentList = list ? list : [];
        if (currentUserId) {
            getConversationsListAPI(
                currentUserId,
                startAt,
                (response: { list: IConversationListData[]; lastVisible: any } | Error) => {
                    if (response instanceof Error) {
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST,
                            payload: {
                                lastVisibleConversationItem: null,
                                conversationsList: {
                                    data: null,
                                    loading: false,
                                    error: {
                                        message: response.message
                                    }
                                } as IConversationsList
                            }
                        });
                    } else {
                        const newCurrentList = currentList.filter((conv) => {
                            return response.list.every((cconv) => cconv.conversationId !== conv.conversationId);
                        });
                        const newConversations: IConversationListData[] = [...newCurrentList, ...response.list];
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST,
                            payload: {
                                lastVisibleConversationItem: response.lastVisible,
                                conversationsList: {
                                    data: newConversations.sort((a, b) => b.updatedAtSeconds - a.updatedAtSeconds),
                                    loading: false,
                                    error: null
                                } as IConversationsList
                            }
                        });
                    }
                }
            );
        }
    };
};

export const initializeChatListSocket = () => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const currentUserId = getState().authenticationReducer.currentUser?.data.userId;
        const list = getState().chatsReducer.conversationsList.data;
        const currentList = list ? list : [];
        if (currentUserId) {
            initializeChatListSocketAPI(
                currentUserId,
                (response: { list: IConversationListData[]; lastVisible: any } | Error) => {
                    if (response instanceof Error) {
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST,
                            payload: {
                                lastVisibleConversationItem: null,
                                conversationsList: {
                                    data: null,
                                    loading: false,
                                    error: {
                                        message: response.message
                                    }
                                } as IConversationsList
                            }
                        });
                    } else {
                        const newCurrentList = currentList.filter((conv) => {
                            return response.list.every((cconv) => cconv.conversationId !== conv.conversationId);
                        });
                        const newConversations: IConversationListData[] = [...newCurrentList, ...response.list];
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_CONVERSATIONS_LIST,
                            payload: {
                                lastVisibleConversationItem: response.lastVisible,
                                conversationsList: {
                                    data: newConversations.sort((a, b) => b.updatedAtSeconds - a.updatedAtSeconds),
                                    loading: false,
                                    error: null
                                } as IConversationsList
                            }
                        });
                    }
                },
                (
                    response:
                        | {
                              list: IConversationListData[];
                              lastVisible: any;
                          }
                        | Error
                ) => {
                    if (response instanceof Error) {
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_SOCKET_CONV_LIST,
                            payload: {
                                conversationsList: {
                                    data: null,
                                    loading: false,
                                    error: {
                                        message: response.message
                                    }
                                } as IConversationsList
                            }
                        });
                    } else {
                        dispatch({
                            type: CHAT_ACTION_TYPES.LOAD_SOCKET_CONV_LIST,
                            payload: {
                                conversationsList: {
                                    data: response.list.sort((a, b) => b.updatedAtSeconds - a.updatedAtSeconds),
                                    loading: false,
                                    error: null
                                } as IConversationsList
                            }
                        });
                    }
                }
            );
        }
    };
};

export const setIsSearchConversationListLoading = (isLoading: boolean) => {
    return (dispatch: AppDispatch, getState: () => ReduxState): void => {
        const currentConversationList = getState().chatsReducer.searchedConversationsList;
        dispatch({
            type: CHAT_ACTION_TYPES.LOAD_SEARCHED_CONVERSATIONS_LIST,
            payload: {
                searchedConversationsList: {
                    data: currentConversationList.data,
                    loading: isLoading,
                    error: currentConversationList.error
                } as IConversationsList
            }
        });
    };
};

export const searchForConversation = (text: string, mode: string) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const currentUserId = getState().authenticationReducer.currentUser?.data.userId;

        const handleResponse = (response: IConversationListData[] | Error) => {
            if (response instanceof Error) {
                dispatch({
                    type: CHAT_ACTION_TYPES.LOAD_SEARCHED_CONVERSATIONS_LIST,
                    payload: {
                        searchedConversationsList: {
                            data: null,
                            loading: false,
                            error: {
                                message: response.message
                            }
                        } as IConversationsList
                    }
                });
            } else {
                dispatch({
                    type: CHAT_ACTION_TYPES.LOAD_SEARCHED_CONVERSATIONS_LIST,
                    payload: {
                        searchedConversationsList: {
                            data: response.sort((a, b) => b.updatedAtSeconds - a.updatedAtSeconds),
                            loading: false,
                            error: null
                        } as IConversationsList
                    }
                });
            }
        };

        // To search the user using mobile number
        if (currentUserId && mode === 'mobile') {
            searchForConversationViaMobileAPI(text, currentUserId, (response) => {
                handleResponse(response);
            });
        } else if (currentUserId && mode === 'loopId') {
            // To search the user using userId
            searchForConversationViaUserId(text, currentUserId, (response) => {
                handleResponse(response);
            });
        } else if (currentUserId) {
            // To search the user using user name
            searchForConversationAPI(text, currentUserId, (response) => {
                handleResponse(response);
            });
        }
    };
};

export const setCurrentlySelectedConversationId = (conversationId: string) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const senderUserId = getState().authenticationReducer.currentUser?.data.userId;
            const patientDetails = getState().chatsReducer.conversationsList.data?.filter(
                (conv) => conv.conversationId === conversationId
            )[0];
            if (patientDetails && senderUserId) {
                await getBatchCountUpdate(
                    patientDetails?.patientUnreadCount,
                    patientDetails?.doctorUnreadCount,
                    senderUserId,
                    null,
                    patientDetails?.patientId,
                    conversationId,
                    null,
                    patientDetails?.isOpen
                );
            }
            dispatch({
                type: CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_CONVERSATION_ID,
                payload: {
                    currentlySelectedConversationId: conversationId
                }
            });
        } catch (e) {
            throw e;
        }
    };
};

export const setIsConversationMessagesLoading = (isLoading: boolean) => {
    return (dispatch: AppDispatch, getState: () => ReduxState): void => {
        const conversationMessages = getState().chatsReducer.conversationMessages;
        dispatch({
            type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
            payload: {
                conversationMessages: {
                    data: conversationMessages.data,
                    loading: isLoading,
                    error: conversationMessages.error
                } as IConversationsList
            }
        });
    };
};

export const loadConversationMessages = (conversationId: string, startAt: any | null) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const { messages, lastVisible } = await loadConversationMessagesAPI(conversationId, startAt);
            if (messages) {
                const previousMessages: IMessageData[] = getState().chatsReducer.conversationMessages.data ?
                    (getState().chatsReducer.conversationMessages.data as any)[conversationId] ?
                        (getState().chatsReducer.conversationMessages.data as any)[conversationId] :
                        [] :
                    [];
                const filteredMessages = messages.filter((msg) => {
                    return previousMessages.every((pmsg) => msg.messageId !== pmsg.messageId);
                });
                let newMessages: IMessageData[] = [...previousMessages];
                if (filteredMessages.length) {
                    newMessages = [...previousMessages, ...filteredMessages];
                }
                newMessages.reverse().sort((a, b) => b.timestamp - a.timestamp);
                dispatch({
                    type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
                    payload: {
                        conversationMessages: {
                            data: {
                                ...getState().chatsReducer.conversationMessages.data,
                                [conversationId]: newMessages
                            },
                            loading: false,
                            error: null
                        } as IConversationMessages,
                        lastVisibleMessage: {
                            ...getState().chatsReducer.lastVisibleMessage,
                            [conversationId]: lastVisible
                        }
                    }
                });
            } else {
                dispatch({
                    type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
                    payload: {
                        conversationMessages: {
                            data: {
                                ...getState().chatsReducer.conversationMessages.data,
                                [conversationId]: null
                            },
                            loading: false,
                            error: {
                                message: 'Something went wrong!'
                            }
                        } as IConversationMessages,
                        lastVisibleMessage: {
                            ...getState().chatsReducer.lastVisibleMessage,
                            [conversationId]: null
                        }
                    }
                });
            }
        } catch (e) {
            dispatch({
                type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
                payload: {
                    conversationMessages: {
                        data: {
                            ...getState().chatsReducer.conversationMessages.data,
                            [conversationId]: null
                        },
                        loading: false,
                        error: e
                    } as IConversationMessages,
                    lastVisibleMessage: {
                        ...getState().chatsReducer.lastVisibleMessage,
                        [conversationId]: null
                    }
                }
            });
        }
    };
};

export const initializeChatSocket = (conversationId: string) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const callback = (querySnapshot: any) => {
            let messagesToAdd: IMessageData[] = [];
            const allConversationMessages = getState().chatsReducer.conversationMessages.data;
            querySnapshot.docChanges().forEach((msg: any) => {
                const obj = msg.doc.data();
                const messageObj: IMessageData = {
                    message: obj.message,
                    messageId: obj.messageId,
                    messageType: obj.messageType,
                    fileId: obj.fileId,
                    fileType: obj.fileName ? obj.fileName.split('.')[obj.fileName.split('.').length - 1] : '',
                    fileName: obj.fileName,
                    fileStatus: obj.fileStatus,
                    senderId: obj.senderId,
                    timestamp: obj.timestamp.seconds,
                    redirect: obj.redirect
                };
                if (msg.type === 'added' || msg.type === 'modified') {
                    messagesToAdd = [...messagesToAdd, messageObj];
                }
            });
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            let oldConversationMessages: IMessageData[] = [];
            if (allConversationMessages && allConversationMessages[conversationId]) {
                oldConversationMessages = allConversationMessages[conversationId];
            }
            oldConversationMessages = oldConversationMessages.filter((el) => {
                return !messagesToAdd.some((msg) => msg.messageId === el.messageId);
            });
            const newConversationMessages: IMessageData[] = [...oldConversationMessages, ...messagesToAdd].sort(
                (a, b) => Number(b.timestamp) - Number(a.timestamp)
            );
            dispatch({
                type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
                payload: {
                    conversationMessages: {
                        data: {
                            ...getState().chatsReducer.conversationMessages.data,
                            [conversationId]: newConversationMessages
                        },
                        loading: false,
                        error: null
                    } as IConversationMessages,
                    lastVisibleMessage: {
                        ...getState().chatsReducer.lastVisibleMessage,
                        [conversationId]: lastVisible
                    }
                }
            });
        };
        await initializeChatSocketAPI(conversationId, callback);
    };
};
export const dispatchMessage = (message: string, conversationId: string, isEndChat: boolean, isLink: boolean) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const messageId = uuid.v4();
        const allConversationMessages = getState().chatsReducer.conversationMessages.data;
        const senderId = getState().authenticationReducer.currentUser?.data.userId;
        const allConversations = [
            ...(getState().chatsReducer.conversationsList.data || []),
            ...(getState().chatsReducer.searchedConversationsList.data || [])
        ];
        const receiverDetails = allConversations.filter(
            (conversation) => conversation.conversationId === conversationId
        )[0];
        const timestamp = FirebaseTimestamp.now();
        try {
            if (receiverDetails && allConversationMessages && senderId) {
                await sendMessageAPI(
                    message,
                    messageId,
                    timestamp,
                    conversationId,
                    senderId,
                    receiverDetails.patientId,
                    receiverDetails.patientName,
                    receiverDetails.patientUnreadCount,
                    receiverDetails.doctorUnreadCount,
                    isEndChat,
                    isLink
                );
                let oldConversationMessages: IMessageData[] = [];

                if (allConversationMessages[conversationId]) {
                    oldConversationMessages = allConversationMessages[conversationId];
                }
                const newMessage: IMessageData = {
                    fileId: '',
                    fileName: '',
                    fileStatus: '',
                    fileType: '',
                    message,
                    messageId,
                    senderId,
                    messageType: isEndChat ? 'rating' : 'text',
                    timestamp: timestamp.seconds,
                    redirect: isLink ? 'rating' : 'text'
                };
                const newConverstaionMessages: IMessageData[] = [...oldConversationMessages, newMessage].sort(
                    (a, b) => Number(b.timestamp) - Number(a.timestamp)
                );
                dispatch({
                    type: CHAT_ACTION_TYPES.LOAD_CONVERSATION_MESSAGES,
                    payload: {
                        conversationMessages: {
                            data: {
                                ...getState().chatsReducer.conversationMessages.data,
                                [conversationId]: newConverstaionMessages
                            },
                            loading: false,
                            error: null
                        } as IConversationMessages
                    }
                });
            }
        } catch (e) {
            throw e;
        }
    };
};

export const clearUploadProgress = (): IAction => {
    return {
        type: CHAT_ACTION_TYPES.SET_UPLOAD_PROGRESS,
        payload: {
            uploadProgress: null
        }
    };
};

export const uploadFileToStorage = (file: File, selectedConversationId: string) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
        const messageId = uuid.v4();
        const senderId = getState().authenticationReducer.currentUser?.data.userId;
        const patientDetails = getState().chatsReducer.currentlySelectedPatient.data;
        const conversationDetails = [
            ...(getState().chatsReducer.conversationsList.data || []),
            ...(getState().chatsReducer.searchedConversationsList.data || [])
        ]?.filter((conversation) => conversation.conversationId === selectedConversationId)[0];
        const timestamp = FirebaseTimestamp.now();
        if (patientDetails && conversationDetails && senderId) {
            try {
                await uploadFileToStorageAPI(
                    file,
                    async (
                        progress: string,
                        uploadFinished: boolean,
                        uploadFailed: boolean,
                        fileName: string,
                        fileExtension: string
                    ) => {
                        dispatch({
                            type: CHAT_ACTION_TYPES.SET_UPLOAD_PROGRESS,
                            payload: {
                                uploadProgress: {
                                    currentProgress: progress,
                                    hasUploadFailed: uploadFailed,
                                    hasUploadStarted: true,
                                    hasUploadFinished: uploadFinished
                                } as IUploadProgress
                            }
                        });
                        const msgObj: IMessageObject = {
                            messageId,
                            senderId,
                            messageType: 'image',
                            fileName: fileName,
                            fileId: messageId,
                            fileType: fileExtension,
                            fileStatus: 'completed',
                            fileTag: fileName,
                            fileIsNotified: false,
                            timestamp: timestamp,
                            fileTimestamp: timestamp,
                            redirect: null,
                            rating: null,
                            checkedIds: [],
                            message: fileExtension.trim() === 'pdf' ? '(Document)' : '(Image)',
                            ratingEntity: null,
                            active: true,
                            synced: false
                        };
                        if (uploadFinished && !uploadFailed) {
                            const response = await sendMessageAttachmentAPI(selectedConversationId, msgObj);
                            if (response) {
                                sendNotificationAPI(
                                    msgObj.message,
                                    senderId,
                                    selectedConversationId,
                                    patientDetails.patientId,
                                    patientDetails.patientName
                                );
                                getBatchCountUpdate(
                                    conversationDetails.patientUnreadCount,
                                    conversationDetails.doctorUnreadCount,
                                    senderId,
                                    msgObj.message,
                                    patientDetails.patientId,
                                    selectedConversationId,
                                    msgObj.timestamp,
                                    conversationDetails.isOpen
                                );
                            }
                        }
                    }
                );
                dispatch({
                    type: CHAT_ACTION_TYPES.SET_UPLOAD_PROGRESS,
                    payload: {
                        uploadProgress: {
                            currentProgress: '0',
                            hasUploadFailed: false,
                            hasUploadStarted: true,
                            hasUploadFinished: false
                        } as IUploadProgress
                    }
                });
            } catch (e) {
                throw e;
            }
        }
    };
};
export const resetChatState = (): IAction => {
    return {
        type: CHAT_ACTION_TYPES.SET_INITIAL_STATE,
        payload: null
    };
};
export const getPatientData = (convId: string) => {
    return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<any> => {
        const normalConversationList = getState().chatsReducer.conversationsList.data || [];
        const searchedConversationList = getState().chatsReducer.searchedConversationsList.data || [];
        const conversationsList = [...normalConversationList, ...searchedConversationList];
        if (conversationsList) {
            const patientId = conversationsList.filter((conv) => conv.conversationId === convId)[0].patientId;
            try {
                dispatch({
                    type: CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_PATIENT_DATA,
                    payload: {
                        currentlySelectedPatient: {
                            data: null,
                            loading: true,
                            error: null
                        } as IPatient
                    }
                });
                const patientData = await getPatientDataAPI(patientId);
                if (patientData) {
                    // do something here
                    dispatch({
                        type: CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_PATIENT_DATA,
                        payload: {
                            currentlySelectedPatient: {
                                data: patientData,
                                loading: false,
                                error: null
                            } as IPatient
                        }
                    });
                } else {
                    throw new Error('Someting went wrong!');
                }
            } catch (e) {
                dispatch({
                    type: CHAT_ACTION_TYPES.SET_CURRENTLY_SELECTED_PATIENT_DATA,
                    payload: {
                        currentlySelectedPatient: {
                            data: null,
                            loading: false,
                            error: {
                                message: e instanceof Error ? e.message : 'Something went wrong!'
                            }
                        } as IPatient
                    }
                });
            }
        }
    };
};
