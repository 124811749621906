import React from 'react';
import styled from 'styled-components';
import { getFirestoreImageUrlAPI } from '../../../adapters';
import { PDFIcon } from '../../../assets/img';
import { Loader } from '../../atoms';
import { IMessageBox } from './types';

const StyledContainer = styled.div<{ isOutgoing: boolean }>`
    width: 100%;
    display: flex;
    padding: 10px;
    max-width: 100%;
    min-width: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    flex-direction: row;
    justify-content: ${(p) => (p.isOutgoing ? 'flex-end' : 'flex-start')};
    align-items: center;
`;

const StyledMessageContent = styled.div<{ isPrimary: boolean }>(
    (p) => `
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.isPrimary ? p.theme.colors.primary : p.theme.colors.black};
    font-weight: 300;
    word-wrap: break-word;
    white-space: pre-wrap;
    align-items: center;
`
);
const StyledMessageContainer = styled.div<{ isPrimary: boolean }>(
    (p) => `
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%;
    min-width: 0;
    border-radius: 10px;
    background-color:
        ${p.isPrimary ? p.theme.colors.primaryBG : p.theme.colors.border};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`
);
const StyledImage = styled.img`
    max-width: 100%;
    max-height: 300px;
    min-height: 120px;
    cursor: pointer;
`;

const StyledMessageMetaContainer = styled.div(
    (p) => `
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
    margin-top: 8px;
`
);
const StyledBold = styled.span`
    margin-right: 3px;
`;
const LoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const MessageBox: React.FunctionComponent<IMessageBox> = ({
    isOutgoing,
    message,
    messageType,
    fileName,
    fileType,
    messageId,
    timestamp
}) => {
    const [src, setSrc] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [
        dateRefreshInterval,
        setDateRefreshInterval
    ] = React.useState<ReturnType<typeof setInterval> | null>(null);
    const [time, setTime] = React.useState(new Date(timestamp * 1000));

    React.useEffect(() => {
        setDateRefreshInterval(
            setInterval(() => {
                setTime(new Date(timestamp * 1000));
            }, 15000)
        );
        return () => {
            if (dateRefreshInterval) {
                clearInterval(dateRefreshInterval);
            }
        };
    }, []);
    React.useEffect(() => {
        let isMounted = true;
        if (fileName) {
            switch (fileType) {
            // switch case for future file types
            default:
                setIsLoading(true);
                getImgSrc(fileName)
                    .then((url) => {
                        isMounted && setSrc(url);
                        setIsLoading(false);
                    });
                return;
            }
        }
        return () => {
            isMounted = false;
        };
    }, [fileName, timestamp]);
    const getImgSrc = async (fileName: string) => {
        try {
            return await getFirestoreImageUrlAPI(fileName);
        } catch (e) {
            throw e;
        }
    };
    const renderContent = () => {
        if (messageType === 'image') {
            if (fileType === 'pdf') {
                return (
                    <StyledImage
                        src={PDFIcon}
                        alt='PDF'
                        onClick={openInNewTab}
                    />
                );
            } else {
                return (
                    <StyledImage
                        src={src}
                        onClick={openInNewTab}
                    />
                );
            }
        } else {
            return message;
        }
    };
    const openInNewTab = () => {
        if (src) {
            window.open(
                src,
                'Image',
                'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1'
            );
        }
    };
    return (
        <StyledContainer isOutgoing={isOutgoing} id={messageId}>
            <StyledMessageContainer isPrimary={isOutgoing}>
                <StyledMessageContent isPrimary={isOutgoing}>
                    {isLoading ? (
                        <LoaderWrapper><Loader /></LoaderWrapper>
                    ) : (
                        (src?.length !== 0 || message?.length !== 0 || false) &&
                        renderContent()
                    )}
                </StyledMessageContent>
                <StyledMessageMetaContainer>
                    <StyledBold>
                        {time.toLocaleDateString('en-US', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                        })}
                        {','}
                    </StyledBold>
                    <span>
                        {time.toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        })}
                    </span>
                </StyledMessageMetaContainer>
            </StyledMessageContainer>
        </StyledContainer>
    );
};

export default MessageBox;
