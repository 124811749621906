import React from 'react';
import styled from 'styled-components';
import { Badge, ProfileIcon } from '../../atoms';
import { IConversationItem } from './types';

const StyledContainer = styled.div<{ isOpen: boolean; isActive: boolean }>(
    (p) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 14px 20px;
    ${!p.isOpen && 'opacity: 0.5;'}
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    &:hover {
        background-color: ${p.theme.colors.primaryBG};
    }
    ${p.isActive && `background-color: ${p.theme.colors.activeBG};`}
`
);
const StyledChatContainer = styled.div`
    min-width: 0;
    flex-grow: 1;
`;
const StyledRow = styled.div<{ isLast: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(p) => (p.isLast ? '0px' : '10px')};
`;
const PatientName = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 600;
    color: ${p.theme.colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    text-transform: capitalize;
`
);
const LastMessageTime = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    opacity: 0.7;
    color: ${p.theme.colors.muted};
    max-width: 125px;
`
);
const LastMessage = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.muted};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
`
);
const ProfileIconWrapper = styled.span`
    margin-right: 15px;
`;

const ConversationItem: React.FunctionComponent<IConversationItem> = ({
    patientName,
    patientProfile,
    lastMessage,
    lastMessageTime,
    unreadCount,
    isOpen,
    isActive,
    onClick,
    conversationId
}) => {
    return (
        <StyledContainer isOpen={isOpen} isActive={isActive} onClick={onClick} id={conversationId}>
            <ProfileIconWrapper>
                <ProfileIcon src={patientProfile} />
            </ProfileIconWrapper>
            <StyledChatContainer>
                <StyledRow isLast={false}>
                    <PatientName>{patientName}</PatientName>
                    <LastMessageTime>{lastMessageTime}</LastMessageTime>
                </StyledRow>
                <StyledRow isLast={true}>
                    <LastMessage>{lastMessage}</LastMessage>
                    {unreadCount !== 0 && <Badge count={unreadCount} />}
                </StyledRow>
            </StyledChatContainer>
        </StyledContainer>
    );
};

export default ConversationItem;
