import styled from 'styled-components';

export const StyledTableRow = styled.div`
    display: table-row;
    width: 100%;
    min-height: 75px;
    background-color: ${(p) => p.theme.colors.white};
    cursor: pointer;
    &:nth-child(even) {
        background-color: ${(p) => p.theme.colors.secondaryBG};
    }
    &:hover {
        background-color: ${(p) => p.theme.colors.primaryBG};
    }
`;
export const StyledTableCell = styled.div(
    (p) => `
    display: table-cell;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    padding: 15px;
`
);

export const StyledNameCell = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledNameText = styled.span`
    margin-left: 15px;
    font-weight: 600;
`;
