import React from 'react';
import { StyledContainer, StyledImage, StyledText } from './styles';
import { ConfusedDoctor as IMG } from '../../../assets/img';
import { IConfusedDoctor } from './types';

const ConfusedDoctor: React.FunctionComponent<IConfusedDoctor> = ({ text }) => {
    return (
        <StyledContainer>
            <StyledImage src={IMG} />
            <h3>Hey!</h3>
            <StyledText>{text}</StyledText>
        </StyledContainer>
    );
};

export default ConfusedDoctor;
