const CHAT_ACTION_TYPES = {
    LOAD_CONVERSATIONS_LIST: 'LOAD_CONVERSATIONS_LIST',
    LOAD_SOCKET_CONV_LIST: 'LOAD_SOCKET_CONV_LIST',
    LOAD_SEARCHED_CONVERSATIONS_LIST: 'LOAD_SEARCHED_CONVERSATIONS_LIST',
    LOAD_CONVERSATION_MESSAGES: 'LOAD_CONVERSATION_MESSAGES',
    SET_CURRENTLY_SELECTED_CONVERSATION_ID:
        'SET_CURRENTLY_SELECTED_CONVERSATION_ID',
    SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
    SET_CURRENTLY_SELECTED_PATIENT_DATA: 'SET_CURRENTLY_SELECTED_PATIENT_DATA',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE'
};

export default CHAT_ACTION_TYPES;
