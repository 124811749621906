import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { AppointmentLinkIcon } from '../../../assets/img';
import { PatientCard } from '../../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { dispatchMessage } from '../../../redux/actions/ChatsActions';
import { AppointmentLink } from '..';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
`;
const StyledQuickActionsContainer = styled.div`
    width: 100%;
    padding: 20px;
    padding-top: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-top: 1px solid ${(p) => p.theme.colors.border};
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;
const StyledTextHeading = styled.div((p) => `
    margin-top: 30px;
    margin-bottom: 5px;
    font-size: ${p.theme.fontSizes.heading}
    colors: ${p.theme.colors.black};
`);
const StyledText = styled.div(
    (p) => `
    font-weight: 500;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-align: center;
`
);
const StyledOptionsContainer = styled.div`
    padding: 20px;
`;
const StyledSVG = styled(SVG)`
    height: 20px;
    cursor: pointer;
    & path {
        pointer-events: all;
        fill: ${(p) => p.theme.colors.primary};
    }
`;
const IconContainer = styled.div`
    max-width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;
const IconBackground = styled.div`
    background-color: ${(p) => p.theme.colors.white};
    border: 1px solid ${(p) => p.theme.colors.border};
    border-radius: 8px;
    height: 34px;
    width: 34px;
    padding: 7px;
    margin-bottom: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
const PatientCardContainer = styled.div`
    margin: 20px;
`;
const QuickActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [appointmentLinkView, setAppointmentLinkView] = React.useState(false);
    const selectedConversationId = useSelector(
        (state: ReduxState) =>
            state.chatsReducer.currentlySelectedConversationId
    );
    const currentPatientData = useSelector(
        (state: ReduxState) => state.chatsReducer.currentlySelectedPatient.data
    );
    const sendMessage = (message: string) => {
        const formattedMsg = message.trim();
        if (formattedMsg.length && selectedConversationId) {
            dispatch(
                dispatchMessage(formattedMsg, selectedConversationId, false, true)
            );
        }
    };
    return (
        <StyledContainer>
            {!appointmentLinkView && (
                <>
                    <PatientCardContainer>
                        {currentPatientData !== null && (
                            <PatientCard {...currentPatientData} />
                        )}
                    </PatientCardContainer>
                    <StyledQuickActionsContainer>
                        <StyledTextHeading>Quick Actions</StyledTextHeading>
                        <StyledOptionsContainer>
                            <IconContainer>
                                <IconBackground
                                    onClick={() => setAppointmentLinkView(true)}
                                >
                                    <StyledSVG src={AppointmentLinkIcon} />
                                </IconBackground>
                                <StyledText>Appointment Link</StyledText>
                            </IconContainer>
                        </StyledOptionsContainer>
                    </StyledQuickActionsContainer>
                </>
            )}
            {appointmentLinkView && (
                <AppointmentLink
                    setAppointmentLinkView={setAppointmentLinkView}
                    shareLink={(link) => sendMessage(link)}
                />
            )}
        </StyledContainer>
    );
};

export default QuickActions;
