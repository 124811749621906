import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    background-color: ${p.theme.colors.error};
    color: ${p.theme.colors.white};
    margin: 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    max-width: 400px;
    font-size: ${p.theme.fontSizes.label};
    font-weight: 500;
`
);
export const StyledImage = styled.img`
    margin-right: 10px;
`;
