/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import firebase from 'firebase';
import {
    IConversationListData,
    IMessageData,
    IMessageObject,
    IPatientData
} from '../redux/reducers/ChatsReducer/types';
import { ageFromDateOfBirthday, dateTransform } from '../utils/utility';
import FirebaseProvider, { FirebaseAuth, FirebaseStorage, Firestore } from './provider';
import { IAdapterResponse } from './types';

let chatSocket: any;
let conversationSocket: any;

export const verifyCaptchaAPI = (phoneNumber: string): IAdapterResponse => {
    // FirebaseAuth.setPersistence(FirebaseAuth.)
    return FirebaseAuth.signInWithPhoneNumber(`+91${phoneNumber}`, window.recaptchaVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            return {
                success: true,
                data: phoneNumber
            };
        })
        .catch((error) => {
            return {
                success: false,
                data: error
            };
        });
};

export const verifyOTPAPI = async (otp: string, phoneNumber: string): Promise<IAdapterResponse> => {
    try {
        const userCreds = await window.confirmationResult.confirm(otp);
        const db = Firestore;
        if (userCreds) {
            const response = await db.collection('user').doc(phoneNumber).get();
            const data = await response.data();
            if (data) {
                if (data.userType.toLowerCase() === 'coordinator') {
                    // alow the user to logoin
                    return {
                        data,
                        success: true
                    };
                } else {
                    throw new Error('Only coordinators are allowed to login');
                }
            } else {
                throw new Error('Failed to do something exceptional');
            }
        } else {
            throw new Error('Invalid OTP. Please enter a valid OTP to proceed');
        }
    } catch (e) {
        let errorMessage = 'Failed to do something exceptional';
        if (e instanceof Error) {
            errorMessage = e.message;
            if ((e as any).code === 'auth/invalid-verification-code') {
                errorMessage = 'Invalid OTP. Please enter a valid OTP to proceed';
            }
        }
        throw new Error(errorMessage);
    }
};
export const signoutUserAPI = () => {
    localStorage.removeItem('currentUser');
    return FirebaseAuth.signOut()
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};
export const getConversationsListAPI = (
    userId: string,
    startAt: any,
    callback: (
        convs:
            | {
                  list: IConversationListData[];
                  lastVisible: any;
              }
            | Error
    ) => void
): void => {
    Firestore.collection('conversation')
        .where('coordinatorId', '==', userId)
        .orderBy('updatedAt', 'desc')
        .startAt(startAt)
        .limit(10)
        .get()
        .then((querySnapshot: firebase.firestore.DocumentData) => {
            if (querySnapshot) {
                const conversations: any[] = [];
                querySnapshot.forEach(
                    (doc: {
                        data: () => {
                            (): any;
                            new (): any;
                            lastMessage: string;
                        };
                    }) => {
                        if (doc.data().lastMessage?.toLowerCase() !== 'welcome to loop health') {
                            conversations.push(doc.data());
                        }
                    }
                );
                callback({
                    list: conversations.map((conv) => {
                        const newConv: IConversationListData = {
                            isOpen: conv.open,
                            lastMessage: conv.lastMessage,
                            patientId: conv.patientId,
                            patientName: conv.patientName,
                            doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                            patientUnreadCount: conv.badgeData[conv.patientId],
                            lastMessageTime: dateTransform(conv.updatedAt.seconds),
                            conversationId: conv.conversationId,
                            updatedAtSeconds: conv.updatedAt.seconds
                        };
                        return newConv;
                    }),
                    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
                });
            } else {
                callback(new Error('Something went wrong!'));
            }
        });
};

export const initializeChatListSocketAPI = (
    userId: string,
    callback: (
        convs:
            | {
                  list: IConversationListData[];
                  lastVisible: any;
              }
            | Error
    ) => void,
    socketCallback: (
        convs:
            | {
                  list: IConversationListData[];
                  lastVisible: any;
              }
            | Error
    ) => void
): void => {
    conversationSocket = Firestore.collection('conversation')
        .where('coordinatorId', '==', userId)
        .orderBy('updatedAt', 'desc')
        .limit(5)
        .onSnapshot((querySnapshot: firebase.firestore.DocumentData) => {
            if (querySnapshot) {
                const conversations: any[] = [];
                querySnapshot.forEach(
                    (doc: {
                        data: () => {
                            (): any;
                            new (): any;
                            lastMessage: string;
                        };
                    }) => {
                        if (doc.data().lastMessage?.toLowerCase() !== 'welcome to loop health') {
                            conversations.push(doc.data());
                        }
                    }
                );
                socketCallback({
                    list: conversations.map((conv) => {
                        const newConv: IConversationListData = {
                            isOpen: conv.open,
                            lastMessage: conv.lastMessage,
                            patientId: conv.patientId,
                            patientName: conv.patientName,
                            doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                            patientUnreadCount: conv.badgeData[conv.patientId],
                            lastMessageTime: dateTransform(conv.updatedAt.seconds),
                            conversationId: conv.conversationId,
                            updatedAtSeconds: conv.updatedAt.seconds
                        };
                        return newConv;
                    }),
                    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
                });
            } else {
                callback(new Error('Something went wrong!'));
            }
        });
    Firestore.collection('conversation')
        .where('coordinatorId', '==', userId)
        .orderBy('updatedAt', 'desc')
        .limit(15)
        .get()
        .then((querySnapshot: firebase.firestore.DocumentData) => {
            if (querySnapshot) {
                const conversations: any[] = [];
                querySnapshot.forEach(
                    (doc: {
                        data: () => {
                            (): any;
                            new (): any;
                            lastMessage: string;
                        };
                    }) => {
                        if (doc.data().lastMessage?.toLowerCase() !== 'welcome to loop health') {
                            conversations.push(doc.data());
                        }
                    }
                );
                callback({
                    list: conversations.map((conv) => {
                        const newConv: IConversationListData = {
                            isOpen: conv.open,
                            lastMessage: conv.lastMessage,
                            patientId: conv.patientId,
                            patientName: conv.patientName,
                            doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                            patientUnreadCount: conv.badgeData[conv.patientId],
                            lastMessageTime: dateTransform(conv.updatedAt.seconds),
                            conversationId: conv.conversationId,
                            updatedAtSeconds: conv.updatedAt.seconds
                        };
                        return newConv;
                    }),
                    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
                });
            } else {
                callback(new Error('Something went wrong!'));
            }
        });
};

export const getFirestoreImageUrlAPI = (img: string): Promise<string> => {
    const storage = FirebaseProvider.storage();
    return storage
        .ref('PatientAppChat/' + img)
        .getDownloadURL()
        .then((url) => {
            return url;
        })
        .catch((e) => {
            throw e;
        });
};

export const loadConversationMessagesAPI = (
    conversationId: string,
    startAt: any
): Promise<{ messages: IMessageData[]; lastVisible: any }> => {
    return Firestore.collection('conversation/' + conversationId + '/messages')
        .orderBy('timestamp', 'desc')
        .startAt(startAt)
        .limit(15)
        .get()
        .then((response) => {
            const messages: any[] = [];
            response.forEach((doc) => {
                messages.unshift(doc.data());
            });
            const formattedMsgs = messages.map((msg) => {
                const newMessageFormat: IMessageData = {
                    message: msg.message,
                    messageId: msg.messageId,
                    messageType: msg.messageType,
                    fileId: msg.fileId,
                    fileType: msg.fileName ? msg.fileName.split('.')[msg.fileName.split('.').length - 1] : '',
                    fileName: msg.fileName,
                    fileStatus: msg.fileStatus,
                    senderId: msg.senderId,
                    timestamp: msg.timestamp.seconds,
                    redirect: msg.redirect
                };
                return newMessageFormat;
            });
            const lastVisible: firebase.firestore.DocumentData = response.docs[response.docs.length - 1];
            return {
                messages: formattedMsgs,
                lastVisible
            };
        })
        .catch((e) => {
            throw e;
        });
};

export const sendMessageAttachmentAPI = (selectedConversationId: string, msgObj: IMessageObject) => {
    return Firestore.collection('conversation')
        .doc(selectedConversationId)
        .collection('messages')
        .doc(msgObj.messageId)
        .set(msgObj)
        .then(() => true)
        .catch((error) => {
            throw error;
        });
};

export const sendMessageAPI = async (
    message: string,
    messageId: string,
    timestamp: any,
    conversationId: string,
    senderId: string,
    receiverId: string,
    receiverName: string,
    receiverUnreadCount: number,
    senderUnreadCount: number,
    isEndChat: boolean,
    isLink: boolean
): Promise<void> => {
    const db = Firestore;
    const messageObject = {
        message,
        senderId,
        messageType: isLink ? 'redirect' : isEndChat ? 'rating' : 'text',
        messageId,
        timestamp: timestamp,
        checkedIds: isEndChat ? [senderId] : [],
        ratingEntity: null,
        active: true,
        synced: true,
        redirect: isLink ? 'call' : null
    };
    try {
        db.collection('conversation')
            .doc(conversationId)
            .collection('messages')
            .doc(messageObject.messageId)
            .set(messageObject)
            .then(() => {
                if (isEndChat) {
                    db.collection('conversation')
                        .doc(conversationId)
                        .update(
                            'open',
                            false,
                            'ratingTask',
                            'conversation/' + conversationId + '/messages/' + messageId
                        )
                        .catch((err) => {
                            throw err;
                        });
                    getBatchCountUpdate(
                        receiverUnreadCount,
                        senderUnreadCount,
                        senderId,
                        message,
                        receiverId,
                        conversationId,
                        timestamp,
                        false
                    );
                } else {
                    sendNotificationAPI(message, senderId, conversationId, receiverId, receiverName);
                    getBatchCountUpdate(
                        receiverUnreadCount,
                        senderUnreadCount,
                        senderId,
                        message,
                        receiverId,
                        conversationId,
                        timestamp,
                        true
                    );
                }
            })
            .catch((e) => {
                throw e;
            });
    } catch (e) {
        throw e;
    }
};

export const sendNotificationAPI = async (
    message: string,
    senderId: string,
    conversationId: string,
    receiverId: string,
    receiverName: string
): Promise<void> => {
    try {
        const userObj: any = await getUserDetailsAPI(senderId);
        const doctorName = userObj.firstName + ' ' + userObj.lastName;
        const data = {
            conversationId: conversationId,
            topicId: receiverId,
            doctorName,
            patientName: receiverName,
            message: message
        };
        const addMessage = FirebaseProvider.functions('asia-south1').httpsCallable('notification-chat');
        addMessage(data).catch((error) => {
            // Getting the Error details.
            throw error;
        });
    } catch (e) {
        throw e;
    }
};

export const getUserDetailsAPI = async (phone: string): Promise<any> => {
    return new Promise((resolve) => {
        Firestore.collection('user')
            .doc(phone)
            .get()
            .then((res) => {
                resolve(res.data());
            })
            .catch((err) => {
                throw err;
            });
    });
};

export const getBatchCountUpdate = (
    receiverUnreadCount: number,
    senderUnreadCount: number,
    senderUserId: string,
    incomingMessage: string | null,
    receiverUserId: string,
    conversationId: string,
    timestamp: any,
    isOpen: boolean
) => {
    const resBadgeData = {
        [receiverUserId]: receiverUnreadCount,
        [senderUserId]: senderUnreadCount
    };
    const db = Firestore;
    resBadgeData[senderUserId] = 0;
    if (incomingMessage) {
        if (resBadgeData[receiverUserId] && resBadgeData[receiverUserId] > 0) {
            resBadgeData[receiverUserId] = resBadgeData[receiverUserId] + 1;
        } else {
            resBadgeData[receiverUserId] = 1;
        }

        db.collection('conversation')
            .doc(conversationId)
            .update('badgeData', resBadgeData, 'lastMessage', incomingMessage, 'open', isOpen, 'updatedAt', timestamp)
            .catch((error) => {
                throw error;
            });
    } else if (!isOpen && timestamp) {
        db.collection('conversation')
            .doc(conversationId)
            .update('badgeData', resBadgeData, 'open', isOpen, 'updatedAt', timestamp)
            .catch((error) => {
                throw error;
            });
    } else {
        db.collection('conversation')
            .doc(conversationId)
            .update('badgeData', resBadgeData, 'open', isOpen)
            .catch((error) => {
                throw error;
            });
    }
};

export const initializeChatSocketAPI = (conversationId: string, callback: (messages: any) => void) => {
    chatSocket = Firestore.collection('conversation/' + conversationId + '/messages')
        .orderBy('timestamp', 'desc')
        .limit(15)
        .onSnapshot((querySnapshot) => {
            if (querySnapshot && window.navigator.onLine) {
                callback(querySnapshot);
            }
        });
};

export const searchForConversationAPI = (
    text: string,
    currentUserId: string,
    callback: (convs: IConversationListData[] | Error) => void
) => {
    const name = text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toLowerCase());
    Firestore.collection('conversation')
        .where('coordinatorId', '==', currentUserId)
        .orderBy('patientName')
        .startAt(name)
        .endAt(text.toLowerCase() + '\uf8ff')
        .limit(5)
        .get()
        .then((res) => {
            if (res) {
                const conversationsList: any[] = [];
                res.forEach((doc) => {
                    if (doc.data().lastMessage?.toLowerCase() !== 'welcome to loop health') {
                        conversationsList.push(doc.data());
                    }
                });
                if (conversationsList.length) {
                    callback(
                        conversationsList.map((conv) => {
                            const newConv: IConversationListData = {
                                isOpen: conv.open,
                                lastMessage: conv.lastMessage,
                                patientId: conv.patientId,
                                patientName: conv.patientName,
                                doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                                patientUnreadCount: conv.badgeData[conv.patientId],
                                lastMessageTime: dateTransform(conv.updatedAt.seconds),
                                conversationId: conv.conversationId,
                                updatedAtSeconds: conv.updatedAt.seconds
                            };
                            return newConv;
                        })
                    );
                } else {
                    callback(new Error('User Not Found'));
                }
            }
        })
        .catch(() => {
            callback(new Error('Unable to search for conversations'));
        });
};

export const searchForConversationViaMobileAPI = async (
    mobile: string,
    currentUserId: string,
    callback: (convs: IConversationListData[] | Error) => void
) => {
    const conversation: any[] = [];
    const users: any[] = [];

    await Firestore.collection('user')
        .where('coordinatorId', '==', currentUserId)
        .orderBy('mobile')
        .startAt(mobile)
        .endAt(mobile + '\uf8ff')
        .get()
        .then((res) => {
            res.forEach((doc) => {
                users.push(doc.data().userId);
            });
        })
        .then(() => {
            if (users.length) {
                users.map(async (userId, index) => {
                    await Firestore.collection('conversation')
                        .where('patientId', '==', userId)
                        .get()
                        .then((querySnapshot: firebase.firestore.DocumentData) => {
                            if (querySnapshot) {
                                querySnapshot.forEach((doc: any) => {
                                    conversation.push(doc.data());
                                });
                            }
                        });
                    if (index === users.length - 1) {
                        if (conversation.length) {
                            callback(
                                conversation.map((conv) => {
                                    const newConv: IConversationListData = {
                                        isOpen: conv.open,
                                        lastMessage: conv.lastMessage,
                                        patientId: conv.patientId,
                                        patientName: conv.patientName,
                                        doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                                        patientUnreadCount: conv.badgeData[conv.patientId],
                                        lastMessageTime: dateTransform(conv.updatedAt.seconds),
                                        conversationId: conv.conversationId,
                                        updatedAtSeconds: conv.updatedAt.seconds
                                    };
                                    return newConv;
                                })
                            );
                        } else {
                            callback(new Error('User Not Found'));
                        }
                    }
                });
            } else {
                callback(new Error('User Not Found'));
            }
        });
};

export const searchForConversationViaUserId = (
    userId: string,
    currentUserId: string,
    callback: (convs: IConversationListData[] | Error) => void
) => {
    Firestore.collection('conversation')
        .where('coordinatorId', '==', currentUserId)
        .orderBy('patientId')
        .startAt(userId)
        .endAt(userId + '\uf8ff')
        .get()
        .then((res) => {
            if (res) {
                const user: any[] = [];
                res.forEach((doc) => {
                    user.push(doc.data());
                });
                if (user.length) {
                    callback(
                        user.map((conv) => {
                            const newConv: IConversationListData = {
                                isOpen: conv.open,
                                lastMessage: conv.lastMessage,
                                patientId: conv.patientId,
                                patientName: conv.patientName,
                                doctorUnreadCount: conv.badgeData[conv.coordinatorId],
                                patientUnreadCount: conv.badgeData[conv.patientId],
                                lastMessageTime: dateTransform(conv.updatedAt.seconds),
                                conversationId: conv.conversationId,
                                updatedAtSeconds: conv.updatedAt.seconds
                            };
                            return newConv;
                        })
                    );
                } else {
                    callback(new Error('User Not Found'));
                }
            }
        });
};

export const uploadFileToStorageAPI = (
    file: File,
    callback: (
        progress: string,
        uploadFinished: boolean,
        uploadFailed: boolean,
        fileName: string,
        fileExtension: string
    ) => void
) => {
    if (file) {
        const fileExtension = `${file.type.split('/')[file.type.split('/').length - 1].toLowerCase()}`;
        const newName = `${file.name
            .split('.')
            .slice(0, -1)
            .join('.')}_${new Date().getTime()}.${fileExtension}`.trim();
        const uploader = FirebaseStorage.ref(`/PatientAppChat/${newName}`).put(file);
        uploader.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            callback(progress.toString(), progress === 100, false, newName, fileExtension);
        });
        uploader.catch(() => {
            callback('0', false, true, newName, fileExtension);
        });
    }
};

export const getPatientDataAPI = (patientId: string) => {
    return Firestore.collection('user')
        .doc(patientId)
        .get()
        .then(async (res) => {
            const userData = res.data();
            if (userData) {
                if (userData.dob) {
                    userData.dob = ageFromDateOfBirthday(userData.dob);
                } else {
                    userData.dob = '';
                }

                let companyName = 'N/A';

                if (userData.employer) {
                    await Firestore.collection('company')
                        .doc(userData.employer)
                        .get()
                        .then((res) => {
                            companyName = res.data()?.name;
                        });
                }

                const patientData: IPatientData = {
                    patientAge: userData.dob || '',
                    patientCompany: companyName,
                    patientGender: (userData.gender || '').trim(),
                    patientMobileNumber: userData.mobile || '',
                    patientProfleUrl: '',
                    patientName: `${userData.firstName || ''} ${userData.lastName || ''}`,
                    patientType: 'membership',
                    patientId,
                    patientBackupMobileNumber: userData.backupPhoneNumber || ''
                };
                return patientData;
            }
        })
        .catch((e) => {
            console.log('ERROR', e);
            throw e;
        });
};

export const checkIfUserAuthAPI = (): Promise<firebase.User | null> => {
    return new Promise((resolve) => {
        FirebaseAuth.onAuthStateChanged((user: firebase.User | null) => {
            resolve(user);
        });
    });
};

export const cancelSockets = () => {
    try {
        // check if the sockets have been initialized, and then unsubscribe
        chatSocket && chatSocket();
        conversationSocket && conversationSocket();
        return true;
    } catch (e) {
        return false;
    }
};
