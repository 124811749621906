import React from 'react';
import { Label, ProfileIcon } from '..';
import { StyledTableRow, StyledTableCell, StyledNameCell, StyledNameText } from './styles';
import { ITableRow } from './types';

const TableRow: React.FunctionComponent<ITableRow> = ({
    patientProfileUrl,
    active,
    patientName,
    patientNumber,
    nextConsultation,
    membershipType,
    patientCompany,
    onClick
}) => {
    return (
        <StyledTableRow onClick={onClick}>
            <StyledTableCell>
                <StyledNameCell>
                    <ProfileIcon size={35} src={patientProfileUrl} />
                    <StyledNameText>{patientName}</StyledNameText>
                </StyledNameCell>
            </StyledTableCell>
            <StyledTableCell>{patientNumber}</StyledTableCell>
            <StyledTableCell>{nextConsultation}</StyledTableCell>
            <StyledTableCell>
                <Label isActive={active}>
                    {active ? 'Active' : 'Inactive'}
                </Label>
            </StyledTableCell>
            <StyledTableCell>{membershipType}</StyledTableCell>
            <StyledTableCell>{patientCompany}</StyledTableCell>
        </StyledTableRow>
    );
};

export default TableRow;
