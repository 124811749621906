import styled from 'styled-components';

export const StyledNavOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
`;
